import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from "../users-index.service";
import {CommonService} from '../../common.service';
import {Router} from "@angular/router";

declare let $: any;

@Component({
  selector: 'app-users-sign-up',
  templateUrl: './users-sign-up.component.html',
  styleUrls: ['./users-sign-up.component.css']
})
export class UsersSignUpComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private commonService: CommonService) { }

  model = {};

  ngOnInit() {
    $(".sign-up").hide();
    $(".sign-up-01").show();

    this.model['user_id'] = 0;
  }

  onSignUp(): void {
    setTimeout(() => {
      this.userService.signUp(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status){
          this.model['user_id'] = resp.content.user_id;
          this.model['email'] = resp.content.email;
          $(".sign-up").hide();
          $(".sign-up-02").show();
        }else{
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);

  }

  onNext(): void {
    setTimeout(() => {
      this.userService.validateEmail(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status){
          this.model['user_id'] = resp.content.user_id;
          this.model['signup_code'] = resp.content.signup_code;
          $(".sign-up").hide();
          $(".sign-up-03").show();
        }else{
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }

  onCompleteSignUp(): void {
    setTimeout(() => {
      this.userService.completeSignUp(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status){
          this.model['user_id'] = resp.content.user_id;
          this.model['username'] = resp.content.username;
          $(".sign-up").hide();
          $(".sign-up-04").show();
        }else{
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }
}
