import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {CustomersService} from '../../../customers/customers.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoansService} from '../../loans.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-loans-send',
  templateUrl: './loans-send.component.html',
  styleUrls: ['./loans-send.component.css']
})
export class LoansSendComponent implements OnInit {

  constructor(private apiService: LoansService,
              private customersService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {};

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_chu_ky_tra_goc').selectpicker('refresh');

    }, 100);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  onUpdate(): void {
    this.apiService.updateStatusModel(this.model, 5)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === resp.content.id;
          });

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }
}
