import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../common.service';
import * as _ from 'lodash';

import {UsersIndexService} from '../users-index.service';
import {GroupsIndexService} from '../../groups/groups-index.service';

declare let $: any;

@Component({
  selector: 'app-users-add',
  templateUrl: 'users-add.component.html',
  styleUrls: ['users-add.component.css'],
  providers: [UsersIndexService, CommonService]
})
export class UsersAddComponent implements OnInit {

  constructor(private groupService: GroupsIndexService,
              private apiService: UsersIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    id: '',
    password: '',
    thumbnail_url: '',
    thumbnail_full_url: '',
    group_id: '',
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  filterParams = {
    s: '',
    page: 1,
  };
  groupUsers: any[] = [];
  groupUserId: any = '';

permissionSettings: any;

  ngOnInit() {
    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));

    if (this.item) {
      this.model = this.item;
      this.groupUserId = this.item.group_id;
      this.model.password = '';
    }
    this.loadingGroupUser();
    setTimeout(() => {
      $('.selectpicker_group_id').selectpicker('refresh');
      $('.selectpicker_group_id').selectpicker('val', this.groupUserId);
    }, 50);
  }



  loadingGroupUser(): void {
    this.groupService.getList(this.filterParams).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.groupUsers = resp.content.items;

      setTimeout(() => {
        $('.selectpicker_group_id').selectpicker('refresh');
        $('.selectpicker_group_id').selectpicker('val', this.groupUserId);
      }, 50);
    });
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loopItem) => {
            return loopItem.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUploadThumbnail(files: FileList): void {
    setTimeout(() => {
      this.apiService.updateThumbnail(this.model.id, files.item(0)).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status) {
          this.model.thumbnail_url = resp.content.thumbnail_url;
          this.model.thumbnail_full_url = resp.content.thumbnail_full_url;

          this.commonService.showMessage({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message.split('\n').join('<br />'),
            type: 'danger'
          });
        }

      });
    }, 50);
  }

}
