import {Component, OnInit} from '@angular/core';
import {UsersIndexService} from '../users/users-index.service';
import {DashboardService} from './dashboard.service';
import {LoansViewComponent} from '../loans/popups/loans-view/loans-view.component';
import {CustomersViewComponent} from '../customers/popups/customers-view/customers-view.component';
import {LoansService} from '../loans/loans.service';
import {CustomersService} from '../customers/customers.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private dashboardService: DashboardService,
              private loanService: LoansService,
              private customerService: CustomersService,
              private modalService: NgbModal) { }


  current_year = '';

  tongvon = '';
  giaingan = '';
  loinhuan_tu0101 = '';
  loinhuan_den3112 = '';

  kh_tiemnang = '';
  kh_cohs_vayvon = '';
  kh_cono_quahan = '';
  kh_thanhvien_khachhang = '';

  hs_donxin_vayvon = '';
  hs_xephang_tindung = '';
  hs_thamdinh_pheduyet = '';
  hs_da_giaingan = '';
  hs_cono_quahan = '';
  hs_cocau_khoanvay = '';
  hs_da_hoantat = '';
  tb_nhacno = '';

  khoan_tt_trehan: any[] = [];
  khoan_tt_toihan: any[] = [];
  hoso_choduyet: any[] = [];
  khachhang_moi: any[] = [];
  loading = true;

  ngOnInit() {
    this.userService.validateAccessToken();

    this.dashboardService.getDashboard().subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.loading = false;
      this.current_year = resp.content.current_year;

      this.tongvon = resp.content.tongvon;
      this.giaingan = resp.content.giaingan;
      this.loinhuan_tu0101 = resp.content.loinhuan_tu0101;
      this.loinhuan_den3112 = resp.content.loinhuan_den3112;

      this.kh_tiemnang = resp.content.kh_tiemnang;
      this.kh_cohs_vayvon = resp.content.kh_cohs_vayvon;
      this.kh_cono_quahan = resp.content.kh_cono_quahan;
      this.kh_thanhvien_khachhang = resp.content.kh_thanhvien_khachhang;

      this.hs_donxin_vayvon = resp.content.hs_donxin_vayvon;
      this.hs_xephang_tindung = resp.content.hs_xephang_tindung;
      this.hs_thamdinh_pheduyet = resp.content.hs_thamdinh_pheduyet;
      this.hs_da_giaingan = resp.content.hs_da_giaingan;
      this.hs_cono_quahan = resp.content.hs_cono_quahan;
      this.hs_cocau_khoanvay = resp.content.hs_cocau_khoanvay;
      this.hs_da_hoantat = resp.content.hs_da_hoantat;
      this.tb_nhacno = resp.content.tb_nhacno;

      this.khoan_tt_trehan = resp.content.khoan_tt_trehan;
      this.khoan_tt_toihan = resp.content.khoan_tt_toihan;
      this.hoso_choduyet = resp.content.hoso_choduyet;
      this.khachhang_moi = resp.content.khachhang_moi;
    });
  }

  modalViewLoan(itemId): void {
    this.loanService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_tasks = resp.content.count_tasks;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_invitations = resp.content.count_invitations;
    });
  }

  modalViewCustomer(itemId): void {
    this.customerService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(CustomersViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
    });
  }

}
