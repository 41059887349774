import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from "../../users/users-index.service";

@Component({
  selector: 'app-settings-index',
  templateUrl: 'settings-index.component.html',
  styleUrls: ['settings-index.component.css']
})
export class SettingsIndexComponent implements OnInit {

  constructor(private userService: UsersIndexService) { }

  notificationSettings = {
    saving_money_first_push: false,
    saving_money_first_email: false,
    saving_money_first_sms: false,
    saving_money_last_push: false,
    saving_money_last_email: false,
    saving_money_last_sms: false,
    loan_before_push: false,
    loan_before_email: false,
    loan_before_sms: false,
    loan_before_short_push: false,
    loan_before_short_email: false,
    loan_before_short_sms: false,
    loan_last_push: false,
    loan_last_email: false,
    loan_last_sms: false,
    loan_notify_first_push: false,
    loan_notify_first_email: false,
    loan_notify_first_sms: false,
    loan_notify_second_push: false,
    loan_notify_second_email: false,
    loan_notify_second_sms: false,
    notify_lawsuit_push: false,
    notify_lawsuit_email: false,
    notify_lawsuit_sms: false,
    notify_handling_asset_push: false,
    notify_handling_asset_email: false,
    notify_handling_asset_sms: false,
    birthday_push: false,
    birthday_email: false,
    birthday_sms: false,
    new_year_push: false,
    new_year_email: false,
    new_year_sms: false,
    corona_push: false,
    corona_email: false,
    corona_sms: false
  };

  ngOnInit() {
    this.userService.validateAccessToken();
    var signedUser_user_id = localStorage.getItem('signedUser_user_id');
    this.userService.getItemWithNotificationSettings(signedUser_user_id).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.notificationSettings = {
        saving_money_first_push: (resp.content['saving_money_first_push']=="1")?true:false,
        saving_money_first_email: (resp.content['saving_money_first_email']=="1")?true:false,
        saving_money_first_sms: (resp.content['saving_money_first_sms']=="1")?true:false,
        saving_money_last_push: (resp.content['saving_money_last_push']=="1")?true:false,
        saving_money_last_email: (resp.content['saving_money_last_email']=="1")?true:false,
        saving_money_last_sms: (resp.content['saving_money_last_sms']=="1")?true:false,
        loan_before_push: (resp.content['loan_before_push']=="1")?true:false,
        loan_before_email: (resp.content['loan_before_email']=="1")?true:false,
        loan_before_sms: (resp.content['loan_before_sms']=="1")?true:false,
        loan_before_short_push: (resp.content['loan_before_short_push']=="1")?true:false,
        loan_before_short_email: (resp.content['loan_before_short_email']=="1")?true:false,
        loan_before_short_sms: (resp.content['loan_before_short_sms']=="1")?true:false,
        loan_last_push: (resp.content['loan_last_push']=="1")?true:false,
        loan_last_email: (resp.content['loan_last_email']=="1")?true:false,
        loan_last_sms: (resp.content['loan_last_sms']=="1")?true:false,
        loan_notify_first_push: (resp.content['loan_notify_first_push']=="1")?true:false,
        loan_notify_first_email: (resp.content['loan_notify_first_email']=="1")?true:false,
        loan_notify_first_sms: (resp.content['loan_notify_first_sms']=="1")?true:false,
        loan_notify_second_push: (resp.content['loan_notify_second_push']=="1")?true:false,
        loan_notify_second_email: (resp.content['loan_notify_second_email']=="1")?true:false,
        loan_notify_second_sms: (resp.content['loan_notify_second_sms']=="1")?true:false,
        notify_lawsuit_push: (resp.content['notify_lawsuit_push']=="1")?true:false,
        notify_lawsuit_email: (resp.content['notify_lawsuit_email']=="1")?true:false,
        notify_lawsuit_sms: (resp.content['notify_lawsuit_sms']=="1")?true:false,
        notify_handling_asset_push: (resp.content['notify_handling_asset_push']=="1")?true:false,
        notify_handling_asset_email: (resp.content['notify_handling_asset_email']=="1")?true:false,
        notify_handling_asset_sms: (resp.content['notify_handling_asset_sms']=="1")?true:false,
        birthday_push: (resp.content['birthday_push']=="1")?true:false,
        birthday_email: (resp.content['birthday_email']=="1")?true:false,
        birthday_sms: (resp.content['birthday_sms']=="1")?true:false,
        new_year_push: (resp.content['new_year_push']=="1")?true:false,
        new_year_email: (resp.content['new_year_email']=="1")?true:false,
        new_year_sms: (resp.content['new_year_sms']=="1")?true:false,
        corona_push: (resp.content['corona_push']=="1")?true:false,
        corona_email: (resp.content['corona_email']=="1")?true:false,
        corona_sms: (resp.content['corona_sms']=="1")?true:false
      };
    })
  }

  onUpdateUserNotificationSettings(notification_setting: any): void {
    setTimeout(() => {
      var signedUser_user_id = localStorage.getItem('signedUser_user_id');
      var setting_value = 0;
      if (this.notificationSettings[notification_setting]) setting_value = 1;
      this.userService.updateNotificationSettings(signedUser_user_id, notification_setting, setting_value).subscribe(resp => {
        if (!resp) {
          return;
        }
      })
    }, 50);
  }

}
