import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../common.service';
import * as _ from 'lodash';

import {AssetsIndexService} from '../../assets-index.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-assets-add-cavet',
  templateUrl: 'assets-add-cavet.component.html',
  styleUrls: ['assets-add-cavet.component.css'],
  providers: [AssetsIndexService, CommonService]
})
export class AssetsAddCavetComponent implements OnInit {

  constructor(private apiService: AssetsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {};

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_tai_san_status').selectpicker('refresh');
      $('.selectpicker_asset_type').selectpicker('refresh');
      $('.selectpicker_loai_tien').selectpicker('refresh');
    }, 50);
  }
  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createCavet(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateCavet(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

}
