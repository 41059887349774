import {Component, Input, OnInit} from '@angular/core';
import {CustomersService} from "../../customers.service";
import {CommonService} from "../../../common.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-customers-jobs',
  templateUrl: './customers-jobs.component.html',
  styleUrls: ['./customers-jobs.component.css']
})
export class CustomersJobsComponent implements OnInit {

  constructor(private customerService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) {
  }

  model = {};

  @Input()
  customer: any;

  @Input()
  items: any[] = [];

  ngOnInit() {
    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onSubmit(): void {
    this.customerService.addJob(this.customer.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.customer = resp.content.customer;
        this.items = resp.content.items;
        this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }

    })
  }

  onRemove(id): void {
    this.customerService.removeJob(id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.customer = resp.content.customer;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }


    })
  }

}
