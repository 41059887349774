import {Component, Input, OnInit} from '@angular/core';
import {LoansService} from "../../loans.service";
import {CommonService} from "../../../common.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-loans-incomes',
  templateUrl: './loans-incomes.component.html',
  styleUrls: ['./loans-incomes.component.css']
})
export class LoansIncomesComponent implements OnInit {

  constructor(private loanService: LoansService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {};

  @Input()
  loan: any;

  @Input()
  items: any[] = [];

  ngOnInit() {

  }

  onSubmit(): void {
    this.loanService.addIncome(this.loan.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.loan = resp.content.loan;
        this.items = resp.content.items;
        this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }

    })
  }

  onRemove(id): void {
    this.loanService.removeIncome(id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.loan = resp.content.loan;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }


    })
  }

}
