import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../common.service';
import * as _ from 'lodash';

import {AssetsIndexService} from '../../assets-index.service';
import {CustomersService} from '../../../customers/customers.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-assets-add',
  templateUrl: 'assets-add.component.html',
  styleUrls: ['assets-add.component.css'],
  providers: [AssetsIndexService, CommonService]
})
export class AssetsAddComponent implements OnInit {

  constructor(private customerService: CustomersService,
              private apiService: AssetsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    asset_type: '',
    stk_ky_han: '',
    contact_ids: {},

    giatri: '',
    giatri_thechap: '',
    giatri_giuho: '',

    bds_dientich_sudung: '',
    bds_dientich_xaydung: '',
    bds_dientich_san_xaydung: '',

    stk_so_tien_gui: '',
    stk_lai_suat: '',
    stk_so_du: '',

    ck_so_luong: '',
    ck_menh_gia: '',
    ck_gia_thi_truong: '',

    kiot_dientich_sudung: '',
    kiot_dai: '',
    kiot_rong: '',
    kiot_cao: '',
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  customerContacts: any[];
  defaultKyHan: any[];
  contactNumber: any[] = [0];

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
      this.model = {
        ...this.model,
        contact_ids: JSON.parse(this.item.contact_ids),

        giatri: this.model.giatri && this.model.giatri.toString().replace('.', ','),
        giatri_thechap: this.model.giatri_thechap && this.model.giatri_thechap.toString().replace('.', ','),
        giatri_giuho: this.model.giatri_giuho && this.model.giatri_giuho.toString().replace('.', ','),

        bds_dientich_sudung: this.model.bds_dientich_sudung && this.model.bds_dientich_sudung.toString().replace('.', ','),
        bds_dientich_xaydung: this.model.bds_dientich_xaydung && this.model.bds_dientich_xaydung.toString().replace('.', ','),
        bds_dientich_san_xaydung: this.model.bds_dientich_san_xaydung && this.model.bds_dientich_san_xaydung.toString().replace('.', ','),

        stk_so_tien_gui: this.model.stk_so_tien_gui && this.model.stk_so_tien_gui.toString().replace('.', ','),
        stk_lai_suat: this.model.stk_lai_suat && this.model.stk_lai_suat.toString().replace('.', ','),
        stk_so_du: this.model.stk_so_du && this.model.stk_so_du.toString().replace('.', ','),

        ck_so_luong: this.model.ck_so_luong && this.model.ck_so_luong.toString().replace('.', ','),
        ck_menh_gia: this.model.ck_menh_gia && this.model.ck_menh_gia.toString().replace('.', ','),
        ck_gia_thi_truong: this.model.ck_gia_thi_truong && this.model.ck_gia_thi_truong.toString().replace('.', ','),

        kiot_dientich_sudung: this.model.kiot_dientich_sudung && this.model.kiot_dientich_sudung.toString().replace('.', ','),
        kiot_dai: this.model.kiot_dai && this.model.kiot_dai.toString().replace('.', ','),
        kiot_rong: this.model.kiot_rong && this.model.kiot_rong.toString().replace('.', ','),
        kiot_cao: this.model.kiot_cao && this.model.kiot_cao.toString().replace('.', ',')
      };
    }

    this.defaultKyHan = JSON.parse(localStorage.getItem('defaultKyHan'));
    this.getCustomerContacts(this.item.customer_id);

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_tai_san_status').selectpicker('refresh');
      $('.selectpicker_asset_type').selectpicker('refresh');
      $('.selectpicker_asset_type').selectpicker('val', this.model.asset_type);
      $('.selectpicker_loai_tien').selectpicker('refresh');
      $('.selectpicker_stk_ky_han').selectpicker('refresh');
      $('.selectpicker_stk_ky_han').selectpicker('val', this.model.stk_ky_han);
      $('.selectpicker_contact_id').selectpicker('refresh');

    }, 50);
  }

  onAddContactUser(): void {
    this.contactNumber.push(this.contactNumber.length);
    setTimeout(() => {
      $('.selectpicker_contact_id').selectpicker('refresh');
    }, 50);
  }

  onRemoveContactUser(itemIndex): void {
    this.contactNumber.splice(itemIndex, 1);
    delete this.model.contact_ids['contact_id_' + itemIndex];

    setTimeout(() => {
      $('.selectpicker_contact_id').selectpicker('refresh');
    }, 50);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
      if (key === 'asset_type') {
        this.model[key] = parseInt($($event.target).val(), 0);
      }
      if (key.slice(0, 10) === 'contact_id') {
        this.model.contact_ids[key] = parseInt($($event.target).val(), 0);
      }
      if (['idCardFSImg', 'idCardBSImg', 'cavetFSImg', 'cavetBSImg'].includes(key)) {
        this.model[key] = $event.target.files.item(0);
      }
    }, 400);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  onUpdate(): void {
    this.apiService.updateItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  getCustomerContacts(customerId): void {
    this.customerService.getContacts(customerId)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          /* Load Option Select */
          this.customerContacts = resp.content.items;

          /* Input Loading */
          if (!_.isEmpty(resp.content.customer.contacts)) {
            this.contactNumber = resp.content.customer.contacts;

            const contactIdArr = Object.entries(this.model.contact_ids).map(e => e[1]);
            this.contactNumber = this.contactNumber.filter(item => contactIdArr.includes(item.id));
          }
        }

        /* Selecting Option */
        setTimeout(() => {
          this.contactNumber.forEach((item, index) => {
            $('.selectpicker_contact_id').selectpicker('refresh');
            $('.selectpicker_contact_id_' + index).selectpicker('val', item.id);
          });
        }, 50);

      });
  }

}
