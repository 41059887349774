import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoansService} from '../../loans.service';
import {CommonService} from '../../../common.service';

declare let $: any;

@Component({
  selector: 'app-loans-comments',
  templateUrl: 'loans-comments.component.html',
  styleUrls: ['loans-comments.component.css']
})
export class LoansCommentsComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              private commonService: CommonService,
              private loanService: LoansService) {
  }

  model = {};

  @Input()
  loan: any;

  @Input()
  items: any[] = [];
  signedUser: string;

  ngOnInit() {
    this.signedUser = localStorage.getItem('signedUser_display_name');
  }

  onSubmit(): void {
    this.loanService.addComment(this.loan.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.loan = resp.content.loan;
        this.items = resp.content.items;
        this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }

    })
  }

  onRemove(comment_id): void {
    this.loanService.removeComment(this.loan.id, comment_id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.loan = resp.content.loan;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }


    })
  }

}
