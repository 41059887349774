import { Component, OnInit } from '@angular/core';
import {SettingsIndexService} from '../settings-index.service';
import {UsersIndexService} from '../../users/users-index.service';
import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SettingsAddGeneralComponent} from '../popups/settings-add-general/settings-add-general.component';

@Component({
  selector: 'app-settings-general',
  templateUrl: 'settings-general.component.html',
  styleUrls: ['settings-general.component.css']
})
export class SettingsGeneralComponent implements OnInit {

  constructor(private settingsService: SettingsIndexService,
              private userService: UsersIndexService,
              private modalService: NgbModal) { }

  filterParams = {
    source: -1,
    s: '',
    page: 1,
  };
  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: any[] = [];
  loading = true;

permissionSettings: any;

  ngOnInit() {
    this.userService.validateAccessToken();
    this.loadList();
    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));
  }



  loadList(): void {
    setTimeout(() => {
      this.settingsService.getList(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
        this.total = resp.content.total;
        this.ppp = resp.content.ppp;
      });
    }, 50);
  }

  modalUpdate(itemId) {
    this.settingsService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(SettingsAddGeneralComponent, {
        // size: 'lg'
      });

      modalRef.componentInstance.modal_action = 'update';
      modalRef.componentInstance.modal_title = `<strong>${resp.content.title}</strong>`;
      modalRef.componentInstance.items = this.items;
      modalRef.componentInstance.item = resp.content;
    });
  }
}
