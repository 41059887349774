import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../common.service';
import * as _ from 'lodash';

import {AssetsIndexService} from "../../assets-index.service";

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-assets-export',
  templateUrl: 'assets-export.component.html',
  styleUrls: ['assets-export.component.css'],
  providers: [AssetsIndexService, CommonService]
})
export class AssetsExportComponent implements OnInit {

  constructor(private apiService: AssetsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {};

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  projects: any[] = [];
  work_packages: any[] = [];

  asset_users: any[] = [];

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }

    /*this.projectService.getList({
      is_personal: 0,
      is_self_created: -1,
      status: -1,
      s: '',
      page: 0,
    }).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.projects = resp.content.items;

      setTimeout(() => {
        $(".selectpicker_add").selectpicker('val', this.model['project_id']);
        this.loadWorkPackages(this.model['project_id'],this.model['wp_id']);
        $(".selectpicker_user_id").selectpicker('refresh');
      }, 50);
    })*/

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $(".selectpicker_loai_tien").selectpicker('refresh');
    }, 50);
  }
  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  /*
  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
      if (key=='project_id'){
        this.loadWorkPackages(this.model[key],this.model['wp_id']);
        this.projectService.getUsers(this.model[key]).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          let project_id = this.model[key];
          let item_index = _.findIndex(this.projects, function(o) { return o.id == project_id; });
          let author = {'user_id':this.projects[item_index]['author_id'], 'user_display_name': this.projects[item_index]['author_display_name']};
          this.asset_users = resp.content.items;
          this.asset_users.unshift(author);

          setTimeout(() => {
            $(".selectpicker_user_id").selectpicker('refresh');
            $(".selectpicker_user_id").selectpicker('val', this.model['user_id']);
          }, 50);
        })
      }
    }, 400);
  }
  */

  loadWorkPackages(project_id, selected_wp_id): void {
    setTimeout(() => {
      /*this.workpackageService.getList({
        is_self_created: -1,
        is_following: -1,
        status: 0,
        s: '',
        project_id: project_id,
        page: 0,
      }).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }

        this.work_packages = resp.content.items;

        setTimeout(() => {
          $(".selectpicker_add_wp").selectpicker('refresh');
          $(".selectpicker_add_wp").selectpicker('val', selected_wp_id);
        }, 50);
      })*/
    }, 50);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1){
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1){
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

}
