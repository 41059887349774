import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AssetsIndexService} from '../../assets-index.service';
import {CommonService} from '../../../common.service';

import * as _ from 'lodash';

declare let $: any;

@Component({
  selector: 'app-assets-users',
  templateUrl: 'assets-users.component.html',
  styleUrls: ['assets-users.component.css']
})
export class AssetsUsersComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              private commonService: CommonService,
              private assetService: AssetsIndexService) {
  }

  model = {};

  @Input()
  asset: any;

  @Input()
  items: any[] = [];

  workPackage = {};
  workPackage_users: any[] = [];

  ngOnInit() {
    /*this.workPackageService.getUsers(this.asset['wp_id']).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      this.workPackage = resp.content.work_package;
      this.workPackage_users = resp.content.items;

      setTimeout(() => {
        $(".selectpicker_username").selectpicker('refresh');
      }, 50);
    })*/
  }

  onSubmit(): void {
    this.assetService.addUser(this.asset.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.asset = resp.content.asset;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã gửi thư mời làm việc đến ' + this.model['title'],
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }

    })
  }

  onRemove(user_id): void {
    this.assetService.removeUser(this.asset.id, user_id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        _.remove(this.items, (loop_item) => {
          return loop_item.user_id === user_id;
        });

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }


    })
  }

}
