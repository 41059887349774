import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loans-payments',
  templateUrl: 'loans-payments.component.html',
  styleUrls: ['loans-payments.component.css']
})
export class LoansPaymentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
