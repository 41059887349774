import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from "../../users/users-index.service";

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as _ from 'lodash';

import {PopupComponent} from "../../_shared/popup/popup.component";
import {CommonService} from '../../common.service';
import {InvitationsIndexService} from "../invitations-index.service";

import {InvitationsViewComponent} from "../invitations-view/invitations-view.component";

@Component({
  selector: 'app-invitations-index',
  templateUrl: 'invitations-index.component.html',
  styleUrls: ['invitations-index.component.css']
})
export class InvitationsIndexComponent implements OnInit {

  constructor(private invitationService: InvitationsIndexService,
              private userService: UsersIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total: number = 0;
  items: any[] = [];

  ngOnInit() {
    this.userService.validateAccessToken();
    this.loadList();
  }

  filterList(): void {
    this.filterParams.page = 1;
    this.loadList();
  }

  loadList(): void {
    setTimeout(() => {
      this.invitationService.getList(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }

        this.items = resp.content.items;
        this.total = resp.content.total;
        this.ppp = resp.content.ppp;
      })
    }, 50);
  }

  modalView(itemId): void {
    this.invitationService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(InvitationsViewComponent, {
        /*size: 'lg'*/
      });
      modalRef.componentInstance.item = resp.content;
    })
  }

  modalUpdateStatus(itemId, status, item) {
    this.invitationService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Thư mời làm việc';
      var action: string = 'Đồng ý';
      if (status==-1){
        action = 'Từ chối';
      }
      switch (item.invitation_type){
        case 'project':
          modalRef.componentInstance.description = `Bạn <strong>${action}</strong> tham gia khách hàng <strong>${item.project_title}</strong>?`;
          break;
        case 'wp':
          modalRef.componentInstance.description = `Bạn <strong>${action}</strong> tham gia hồ sơ vay <strong>${item.wp_title}</strong>?`;
          break;
        default:
          break;
      }

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.invitationService.updateStatus(itemId, status).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          let item_index = _.findIndex(this.items, function(o) { return o.id == itemId; });
          this.items[item_index].status = status;

          this.commonService.showMessage({
            message: 'Đã phản hồi thành công!',
            type: 'success',
          });
        });
      });
    });

  }

  modalDelete(itemId) {
    this.invitationService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Xóa dữ liệu';
      modalRef.componentInstance.description = `Bạn có muốn xóa thư mời làm việc <strong>${resp.content.title}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.invitationService.deleteItem(itemId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          _.remove(this.items, (loop_item) => {
            return loop_item.id === itemId;
          });

          this.commonService.showMessage({
            message: 'Dữ liệu đã được xóa khỏi hệ thống',
            type: 'success',
          });
        });
      });
    });

  }

}
