import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class GroupsIndexService {

  constructor(private http: HttpClient) { }

  getList(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}groups/list/p/${filterParams.page}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/detail/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  createItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/create?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/update/id/${model.id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updatePermissionSettings(model: any): Observable<any> {
    return;
  }

  deleteItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/delete/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }
}
