import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as _ from 'lodash';

import {CommonService} from '../../common.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-reports-business-chart',
  templateUrl: 'reports-business-chart.component.html',
  styleUrls: ['reports-business-chart.component.css']
})
export class ReportsBusinessChartComponent implements OnInit {

  constructor(private commonService: CommonService,
              private modalService: NgbModal) { }

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  ];


  filterParams = {
    s: '',
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total: number = 0;
  items: any[] = [];

  ngOnInit() {

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.filter_time_period_selectpicker').selectpicker();
    }, 50);
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = $($event.target).val();
      this.loadList();

    }, 400);
  }
  loadList(): void {
    setTimeout(() => {

    }, 50);
  }
}
