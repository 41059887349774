import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {APP_CONFIG} from "../app.config";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashboard(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    let url = `${APP_CONFIG.apiUrl}home/dashboard?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url)
  }
}
