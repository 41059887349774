import {Component, Input, OnInit} from '@angular/core';
import {LoansService} from '../../loans.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import * as _ from 'lodash';
import { toString } from '@ng-bootstrap/ng-bootstrap/util/util';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-loans-credits',
  templateUrl: './loans-credits.component.html',
  styleUrls: ['./loans-credits.component.css']
})
export class LoansCreditsComponent implements OnInit {

  constructor(private apiService: LoansService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    ce_chitieu: '',
    ce_chucvu: '',
    ce_hocvan: '',
    ce_lk_xahoi: '',
    ce_nguoi_dtn: '',
    ce_nguoi_phuthuoc: '',
    ce_sinhsong: '',
    ce_taisan: '',
    ce_tg_cutru: '',
    ce_tg_lamviec: '',
    ce_thunhap: '',
    ce_thunhap_dtn: '',
    ce_thunhap_khac: '',
    ce_tuoi: '',
    ce_vay_tctd_khac: '',

    ce_ls_no_xau: false,
    ce_ls_no_quahan: false,
    ce_chuavay: false
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  totalPoint: any;

  getNumberValue(a: any) {
    return a ? a.toString() : 0;
  }

  ngOnInit() {
    if (this.item) {
      this.model = this.item;

      this.model = {
        ...this.model,
        ce_chitieu: this.getNumberValue(this.item.ce_chitieu) ,
        ce_chucvu: this.getNumberValue(this.item.ce_chucvu),
        ce_hocvan: this.getNumberValue(this.item.ce_hocvan),
        ce_lk_xahoi: this.getNumberValue(this.item.ce_lk_xahoi),
        ce_nguoi_dtn: this.getNumberValue(this.item.ce_nguoi_dtn),
        ce_nguoi_phuthuoc: this.getNumberValue(this.item.ce_nguoi_phuthuoc),
        ce_sinhsong: this.getNumberValue(this.item.ce_sinhsong),
        ce_taisan: this.getNumberValue(this.item.ce_taisan),
        ce_tg_cutru: this.getNumberValue(this.item.ce_tg_cutru),
        ce_tg_lamviec: this.getNumberValue(this.item.ce_tg_lamviec),
        ce_thunhap: this.getNumberValue(this.item.ce_thunhap),
        ce_thunhap_dtn: this.getNumberValue(this.item.ce_thunhap_dtn),
        ce_thunhap_khac: this.getNumberValue(this.item.ce_thunhap_khac),
        ce_tuoi: this.getNumberValue(this.item.ce_tuoi),
        ce_vay_tctd_khac: this.getNumberValue(this.item.ce_vay_tctd_khac),

        ce_chuavay: this.item.ce_chuavay == '1',
        ce_ls_no_quahan: this.item.ce_ls_no_quahan == '1',
        ce_ls_no_xau: this.item.ce_ls_no_xau == '1'
      };

    }

    this.initMaterialWizard();

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_customer_id').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_1').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_2').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_3').selectpicker('refresh');
    }, 50);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();

      console.log(key + ' : ' + $($event.target).val());
    }, 400);
  }

  getRoundNumber(val): number {
    return Math.round(val * 100) / 100;
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateCredits(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  initMaterialWizard() {
    $('.card-wizard').bootstrapWizard({
      tabClass: 'nav nav-pills',
      nextSelector: '.btn-next',
      previousSelector: '.btn-previous',
    });

    setTimeout(function() {
      $('.card.card-wizard').addClass('active');
    }, 600);

    function refreshAnimation($wizard, index) {
      const $total = $wizard.find('.nav li').length;
      let $li_width = 100 / $total;

      const total_steps = $wizard.find('.nav li').length;
      let move_distance = $wizard.width() / total_steps;
      let index_temp = index;
      let vertical_level = 0;

      const mobile_device = $(document).width() < 600 && $total > 3;

      if (mobile_device) {
        move_distance = $wizard.width() / 2;
        index_temp = index % 2;
        $li_width = 50;
      }

      $wizard.find('.nav li').css('width', $li_width + '%');

      const step_width = move_distance;
      move_distance = move_distance * index_temp;

      const $current = index + 1;

      if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
        move_distance -= 8;
      } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
        move_distance += 8;
      }

      if (mobile_device) {
        vertical_level = index / 2;
        vertical_level = vertical_level * 38;
      }

      $wizard.find('.moving-tab').css('width', step_width);
      $('.moving-tab').css({
        transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

      });
    }
  }

}
