import {Component, Input, OnInit} from '@angular/core';
import {LoansService} from '../../loans.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupComponent} from '../../../_shared/popup/popup.component';

declare let $: any;

@Component({
  selector: 'app-loans-interests',
  templateUrl: 'loans-interests.component.html',
  styleUrls: ['loans-interests.component.css']
})


export class LoansInterestsComponent implements OnInit {

  constructor(private apiService: LoansService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal,
              private modalService: NgbModal) {
  }

  model = {
    is_interest: 0
  };

  modelInterest = {
    is_interest: 1
  };

  @Input() items: any[];
  @Input() item: any;

  roots: any[];
  interests: any[];

  totalAtmRoot = 0;
  totalAtmInterest = 0;
  totalAtmPaidRoot = 0;
  totalAtmPaidInterest = 0;
  status = 0;

  reconstructedRoots: any[];
  reconstructedInterests: any[];

  reconstructedTotalAtmRoot = 0;
  reconstructedTotalAtmInterest = 0;
  reconstructedTotalAtmPaidRoot = 0;
  reconstructedTotalAtmPaidInterest = 0;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }
    this.onLoad(0);
    this.onLoad(1);
    this.onLoadReconstructedPayments(0);
    this.onLoadReconstructedPayments(1);

    this.initMaterialWizard();

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

  }

  onLoad(isInterest): any {
    this.apiService.getListPayments(this.item.id, isInterest, 0).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.items = resp.content.items;
      this.setLists(this.items, isInterest, 0);

      return resp.status;
    });
  }

  onLoadReconstructedPayments(isInterest): any {
    this.apiService.getListPayments(this.item.id, isInterest, 1).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.items = resp.content.items;
      this.setLists(this.items, isInterest, 1);

      return resp.status;
    });
  }

  private setLists(items, isInterest, isReconstructed): void {
    if (isReconstructed === 0) {
      if (isInterest === 0) {
        this.roots = items;
        this.totalAtmRoot = items.reduce((a, currentValue) => +a + +currentValue.amt, 0);
        this.totalAtmPaidRoot = items.reduce((a, currentValue) => +a + +currentValue.paid_amt, 0);
      } else {
        this.interests = items;
        this.totalAtmInterest = items.reduce((a, currentValue) => +a + +currentValue.amt, 0);
        this.totalAtmPaidInterest = items.reduce((a, currentValue) => +a + +currentValue.paid_amt, 0);
      }
    } else {
      if (isInterest === 0) {
        this.reconstructedRoots = items;
        this.reconstructedTotalAtmRoot = items.reduce((a, currentValue) => +a + +currentValue.amt, 0);
        this.reconstructedTotalAtmPaidRoot = items.reduce((a, currentValue) => +a + +currentValue.paid_amt, 0);
      } else {
        this.reconstructedInterests = items;
        this.reconstructedTotalAtmInterest = items.reduce((a, currentValue) => +a + +currentValue.amt, 0);
        this.reconstructedTotalAtmPaidInterest = items.reduce((a, currentValue) => +a + +currentValue.paid_amt, 0);
      }
    }
  }

  onChangeModelInput(key, $event, isInterest: number = 0): void {
    setTimeout(() => {
      this.modelInterest[key] = $($event.target).val();
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onNew(isInterest: number = 0): void {
    let tempModel = this.model;
    if (isInterest) {
      tempModel = this.modelInterest;
    }
    this.apiService.createPayment(tempModel, this.item.id)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.items = resp.content.items.filter(item => item.is_interest === isInterest.toString());
          this.setLists(this.items, isInterest, 0);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  onRemove(interestId: number, isInterest: number = 0): void {
    const modalRef = this.modalService.open(PopupComponent, {
      size: 'sm'
    });
    modalRef.componentInstance.title = 'Xóa dữ liệu';
    modalRef.componentInstance.description = `Bạn có muốn xóa không?`;
    modalRef.result.then(result => {
      if (result !== 'Confirmed') {
        return;
      }

      this.apiService.deletePayment(interestId).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status) {
          this.items = resp.content.items.filter(item => item.is_interest === isInterest.toString());
          this.setLists(this.items, isInterest, 0);

          this.commonService.showMessage({
            message: 'Đã xoá thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message.split('\n').join('<br />'),
            type: 'danger'
          });
        }
      });
    });

  }

  onRecalculatePrincipal(): void {
    this.apiService.recalculatePrincipal(this.item.id)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        this.status = this.onLoad(0);
      });
  }

  onRecalculateInterest(): void {
    this.apiService.recalculateInterest(this.item.id)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        this.status = this.onLoad(1);
      });
  }

  onRecalculate(isInterest): void {
    this.apiService.recalculatePayments(this.item.id, isInterest)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        this.status = this.onLoad(isInterest);

      });
  }

  initMaterialWizard() {
    $('.card-wizard').bootstrapWizard({
      tabClass: 'nav nav-pills',
      nextSelector: '.btn-next',
      previousSelector: '.btn-previous',
    });

    setTimeout(() => {
      $('.card.card-wizard').addClass('active');
    }, 600);

    function refreshAnimation($wizard, index) {
      const $total = $wizard.find('.nav li').length;
      let $li_width = 100 / $total;

      const total_steps = $wizard.find('.nav li').length;
      let move_distance = $wizard.width() / total_steps;
      let index_temp = index;
      let vertical_level = 0;

      const mobile_device = $(document).width() < 600 && $total > 3;

      if (mobile_device) {
        move_distance = $wizard.width() / 2;
        index_temp = index % 2;
        $li_width = 50;
      }

      $wizard.find('.nav li').css('width', $li_width + '%');

      const step_width = move_distance;
      move_distance = move_distance * index_temp;

      const $current = index + 1;

      if ($current === 1 || (mobile_device === true && (index % 2 === 0))) {
        move_distance -= 8;
      } else if ($current === total_steps || (mobile_device === true && (index % 2 === 1))) {
        move_distance += 8;
      }

      if (mobile_device) {
        vertical_level = index / 2;
        vertical_level = vertical_level * 38;
      }

      $wizard.find('.moving-tab').css('width', step_width);
      $('.moving-tab').css({
        transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
      });
    }
  }
}
