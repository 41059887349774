import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-invitations-view',
  templateUrl: 'invitations-view.component.html',
  styleUrls: ['invitations-view.component.css']
})
export class InvitationsViewComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
