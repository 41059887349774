import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoansService} from '../../loans.service';
import {CommonService} from '../../../common.service';

declare let $: any;
@Component({
  selector: 'app-loans-view',
  templateUrl: './loans-view.component.html',
  styleUrls: ['./loans-view.component.css']
})
export class LoansViewComponent implements OnInit {

  constructor(private apiService: LoansService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    contract_code: '',
    contract_code_old: '',

    customer_code: '',
    customer_title: '',
    customer_status: '',
    customer_group_code: '',
    customer_group: '',
    ma_nhomvay: '',
    business: '',

    employee_group_name: '',
    employee_display_name: '',
    employee_id: '',

    loan_purpose_int: '',
    loan_purpose: '',
    purpose: '',

    description: '',

    hanmuc_tindung: '',
    currency_type: '',
    ky_han: '',
    dv_tinh_kyhan: '',
    so_the_thanh_vien: '',

    nhom_no: '',
    kieu_vay: '',
    lbl_date_added: '',
    lbl_ngay_giaingan: '',

    lbl_ngay_denhan: '',
    lbl_ngay_giahan: '',
    lbl_ngay_giaodich_cuoicung: '',

    cach_tinh_lai: '',
    lai_suat: '',
    ty_le_du_thu_lai: '',

    chu_ky_tra_goc: '',
    lbl_ngay_tragoc: '',

    chu_ky_tra_lai: '',
    lbl_ngay_tralai: '',
  };


  @Input() items: any[];
  @Input() item: any;

  roots: any[];
  interests: any[];

  paymentTotalGoc: any = 0;
  paymentTotalLai: any = 0;
  paymentTotalPaidGoc: any = 0;
  paymentTotalPaidLai: any = 0;
  rootStatus: any = 0;
  interestStatus: any = 0;
  status: any = 0;

  totalPoint: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;

      this.totalPoint = this.item.ce_tuoi / 2 + +this.item.ce_hocvan / 2 + +this.item.ce_taisan + +this.item.ce_thunhap
        + +this.item.ce_chitieu + +this.item.ce_chucvu / 2 + +this.item.ce_tg_lamviec * 8 / 10
        + +this.item.ce_thunhap_khac * 7 / 10 + +this.item.ce_thunhap_dtn / 2 + +this.item.ce_lk_xahoi / 2
        + +this.item.ce_tg_cutru / 2 + +this.item.ce_nguoi_phuthuoc / 2 + +this.item.ce_sinhsong / 2
        + +this.item.ce_nguoi_dtn / 2 + +this.item.ce_vay_tctd_khac + +(this.item.ce_chuavay == 1 && 7 )
        + +(this.item.ce_ls_no_quahan == 1 && -5 ) + +(this.item.ce_ls_no_xau == 1 && -10 );
      this.totalPoint = Math.round(this.totalPoint * 100) / 100;
    }

    this.onLoad(0);
    this.onLoad(1);

    this.initMaterialWizard();
  }

  onLoad(isInterest): void {
    this.apiService.getListPayments(this.item.id, isInterest, 0).subscribe( resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.items = resp.content.items;
      this.setLists(this.items, isInterest);

      return resp.status;
    });
  }
  setLists(items, isInterest): void {
    if (isInterest === 0) {
      this.roots = items;
      this.paymentTotalGoc = items.reduce((a, currentValue) => +a + +currentValue.amt, 0);
      this.paymentTotalPaidGoc = items.reduce((a, currentValue) => +a + +currentValue.paid_amt, 0);
    } else {
      this.interests = items;
      this.paymentTotalLai = items.reduce((a, currentValue) => +a + +currentValue.amt, 0);
      this.paymentTotalPaidLai = items.reduce((a, currentValue) => +a + +currentValue.paid_amt, 0);
    }
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  initMaterialWizard() {
    $('.card-wizard').bootstrapWizard({
      tabClass: 'nav nav-pills',
      nextSelector: '.btn-next',
      previousSelector: '.btn-previous',
    });

    setTimeout(() => {
      $('.card.card-wizard').addClass('active');
    }, 600);
  }

  modalDownloadToTrinh(itemId, template): void {
    this.apiService.downloadToTrinh(itemId, template);
  }

  modalDownloadBBDG(itemId, template): void {
    this.apiService.modalDownloadBBDG(itemId, template);
  }

  modalDownloadCoCau(itemId, template): void {
    this.apiService.modalDownloadCoCau(itemId, template);
  }

  modalDownload(itemId, template): void {
    this.apiService.downloadHopDong(itemId, template);
  }
}
