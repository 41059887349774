import {Component, Input, OnInit} from '@angular/core';
import {LoansService} from '../../loans.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
  selector: 'app-loans-related-images',
  templateUrl: './loans-related-images.component.html',
  styleUrls: ['./loans-related-images.component.css']
})
export class LoansRelatedImagesComponent implements OnInit {

  constructor(private loanService: LoansService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    attachments: [],
    image_url: '',
    image_full_url: ''
  };

  @Input()
  loan: any = {};

  @Input()
  items: any[] = [];

  @Input()
  attachments: any[] = [];

  ngOnInit() {
    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
    }, 50);
  }
  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }
  onSubmit(): void {
    this.loanService.addRelatedImage(this.loan.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.loan = resp.content.loan;
        this.items = resp.content.items;
        // this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }

    });
  }

  onRemove(id): void {
    this.loanService.removeRelatedImage(id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.loan = resp.content.loan;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }


    });
  }

  onToggleVisibility(itemId): void {

  }

  onUploadThumbnail(itemId, files: FileList): void {
    setTimeout(() => {
      this.loanService.uploadRelatedImage(parseInt(itemId, 0), files.item(0))
        .subscribe(resp => {
          if (!resp) {
            return;
          }
          if (resp.status) {
            this.attachments.push(resp.content);
            this.model.attachments = this.attachments;
            this.model.image_url = resp.content.image_url;
            this.model.image_full_url = resp.content.image_full_url;
            this.commonService.showMessage({
              message: 'Thêm thành công',
              type: 'success'
            });
          } else {
            this.commonService.showMessage({
              message: resp.message.split('\n').join('<br />'),
              type: 'danger'
            });
          }
        });
    }, 50);
  }
}
