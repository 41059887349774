import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {CustomersService} from '../../../customers/customers.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoansService} from '../../loans.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-loans-add',
  templateUrl: './loans-add.component.html',
  styleUrls: ['./loans-add.component.css']
})
export class LoansAddComponent implements OnInit {

  constructor(private apiService: LoansService,
              private customersService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    customer_code: '',
    customer_title: '',
    customer_status: '',
    customer_group_code: '',
    ma_nhomvay: '',
    business: '',

    employee_group_name: '',
    employee_display_name: '',
    employee_id: '',

    loan_purpose_int: '',
    purpose: '',

    description: '',

    hanmuc_tindung: '',
    currency_type: '',
    ky_han: '',
    dv_tinh_kyhan: '',

    nhom_no: '',
    kieu_vay: '',
    lbl_date_added: '',
    lbl_ngay_giaingan: '',

    lbl_ngay_denhan: '',
    lbl_ngay_giahan: '',
    lbl_ngay_giaodich_cuoicung: '',

    cach_tinh_lai: '',
    ty_le_du_thu_lai: '',
    lai_suat: '',
    lai_suat_truochan: '',
    tra_hang_thang_min: '',
    dinhky_dieuchinh_laisuat: '',
    sotien_tietkiem_toida: '',

    chu_ky_tra_goc: '',
    lbl_ngay_tragoc: '',

    chu_ky_tra_lai: '',
    lbl_ngay_tralai: '',
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;
  listConsts = {
    currency_type: '',
    cach_tinh_lai: '',
    nhom_no: '',
    asset_type_label: '',
    chu_ky_tra_goc: '',
    chu_ky_tra_lai: '',
    dv_tinh_kyhan: '',
    kieu_vay: '',
    purpose: '',
    business: '',
    listEmployees: {id: '', group_name: '', display_name: ''},
  };


  ngOnInit() {

    if (this.item) {
      this.model = this.item;

      this.model = {
        ...this.model,
        lai_suat: this.item.lai_suat.toString().replace('.', ','),
        lai_suat_truochan: this.item.lai_suat_truochan.toString().replace('.', ','),
        tra_hang_thang_min: this.item.tra_hang_thang_min.toString().replace('.', ','),
        dinhky_dieuchinh_laisuat: this.item.dinhky_dieuchinh_laisuat.toString().replace('.', ','),
        sotien_tietkiem_toida: this.item.sotien_tietkiem_toida.toString().replace('.', ',')
      };
    }
    this.loadCustomer();

    this.listConsts = {
      currency_type : JSON.parse(localStorage.getItem('listConsts_currency_type')),
      cach_tinh_lai : JSON.parse(localStorage.getItem('listConsts_cach_tinh_lai')),
      nhom_no : JSON.parse(localStorage.getItem('listConsts_nhom_no')),
      asset_type_label : JSON.parse(localStorage.getItem('listConsts_asset_type_label')),
      chu_ky_tra_goc : JSON.parse(localStorage.getItem('listConsts_chu_ky_tra_goc')),
      chu_ky_tra_lai : JSON.parse(localStorage.getItem('listConsts_chu_ky_tra_lai')),
      dv_tinh_kyhan : JSON.parse(localStorage.getItem('listConsts_dv_tinh_kyhan')),
      kieu_vay : JSON.parse(localStorage.getItem('listConsts_kieu_vay')),
      purpose : JSON.parse(localStorage.getItem('listConsts_purpose')),
      business : JSON.parse(localStorage.getItem('listConsts_business')),
      listEmployees: JSON.parse(localStorage.getItem('listEmployees')),
    };

    this.initMaterialWizard();

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_chu_ky_tra_goc').selectpicker('refresh');
      $('.selectpicker_chu_ky_tra_goc').selectpicker('val', this.model.chu_ky_tra_goc);

      $('.selectpicker_chu_ky_tra_lai').selectpicker('refresh');
      $('.selectpicker_chu_ky_tra_lai').selectpicker('val', this.model.chu_ky_tra_lai);

      $('.selectpicker_dv_tinh_kyhan').selectpicker('refresh');
      $('.selectpicker_dv_tinh_kyhan').selectpicker('val', this.model.dv_tinh_kyhan);

    }, 100);
  }

  loadCustomer(): void {
    this.customersService.getItem(this.item.customer_id)
      .subscribe(resp => {
        if (!resp) {
          return;
        }
        this.model.customer_group_code = resp.content.item.customer_group_code;
        this.model.ma_nhomvay = resp.content.item.ma_nhomvay;
      });
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();

      if (key === 'employee_id' || key === 'employee_group_name' || key === 'employee_display_name') {
        let arr: any;
        if (key === 'employee_id') {
          // @ts-ignore
          arr = this.listConsts.listEmployees.map(item => item.id);
        }
        if (key === 'employee_group_name') {
          // @ts-ignore
          arr = this.listConsts.listEmployees.map(item => item.group_name);
        }
        if (key === 'employee_display_name') {
          // @ts-ignore
          arr = this.listConsts.listEmployees.map(item => item.display_name);
        }
        const index = arr.indexOf($($event.target).val());
        this.selectRelationEmployee(index);
      }
    }, 400);
  }

  selectRelationEmployee(index): void {
    this.model.employee_group_name = typeof this.listConsts.listEmployees[index].group_name !== 'undefined' ? this.listConsts.listEmployees[index].group_name : '' ;
    this.model.employee_id = this.listConsts.listEmployees[index].id;
    this.model.employee_display_name = this.listConsts.listEmployees[index].display_name;

    setTimeout(() => {
      $('.selectpicker_employee_group_name').selectpicker('render');
      $('.selectpicker_employee_id').selectpicker('render');
      $('.selectpicker_employee_display_name').selectpicker('render');
    }, 50);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  onUpdate(): void {
    this.apiService.updateFull(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  initMaterialWizard() {
    $('.card-wizard').bootstrapWizard({
      tabClass: 'nav nav-pills',
      nextSelector: '.btn-next',
      previousSelector: '.btn-previous',

      onPrevious(tab, navigation, index) {
      },

      onNext(tab, navigation, index) {
      },

      onInit(tab, navigation, index) {

      },

      onTabClick(tab, navigation, index) {
      },

      onTabShow(tab, navigation, index) {

      }
    });

    setTimeout(function() {
      $('.card.card-wizard').addClass('active');
    }, 600);

    function refreshAnimation($wizard, index) {
      const $total = $wizard.find('.nav li').length;
      let $li_width = 100 / $total;

      const total_steps = $wizard.find('.nav li').length;
      let move_distance = $wizard.width() / total_steps;
      let index_temp = index;
      let vertical_level = 0;

      const mobile_device = $(document).width() < 600 && $total > 3;

      if (mobile_device) {
        move_distance = $wizard.width() / 2;
        index_temp = index % 2;
        $li_width = 50;
      }

      $wizard.find('.nav li').css('width', $li_width + '%');

      const step_width = move_distance;
      move_distance = move_distance * index_temp;

      const $current = index + 1;

      if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
        move_distance -= 8;
      } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
        move_distance += 8;
      }

      if (mobile_device) {
        vertical_level = index / 2;
        vertical_level = vertical_level * 38;
      }

      $wizard.find('.moving-tab').css('width', step_width);
      $('.moving-tab').css({
        transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

      });
    }
  }

}
