import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {CommonService} from '../../common.service';
import {ReportsIndexService} from '../reports-index.service';



@Component({
  selector: 'app-reports-employee-activity',
  templateUrl: 'reports-employee-activity.component.html',
  styleUrls: ['reports-employee-activity.component.css']
})
export class ReportsEmployeeActivityComponent implements OnInit {

  constructor(private reportService: ReportsIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total: number = 0;
  items: {
    'id': '1',
    'user_display_name': '',

    'tong_khach_hang': '',
    'tong_khach_hang_dang_cham_soc': '',
    'tong_ho_so_da_vay': '',
    'tong_so_ho_so_tra_dung_han': '',
    'tong_so_ho_so_tra_tre_han': '',
    'tong_so_ho_so_nguy_co_mat_von': '',

    'tong_amt': any,
    'tong_paid_amt': any,
    'tong_paid_interest_amt': any,
    'so_tien_lai_du_kien_nhan_duoc': any,
    'so_tien_goc_con_phai_thanh_toan': any
  }[] = [];
  loading = true;

  ngOnInit() {
    this.loadList();

  }

  filterList(): void {
  }

  loadList(): void {
    setTimeout(() => {
      this.reportService.getReportEmployee(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
      });
    }, 50);
  }
}
