import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-report-income-view',
  templateUrl: './report-income-view.component.html',
  styleUrls: ['./report-income-view.component.css']
})
export class ReportIncomeViewComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  @Input()
  item: any;

  model = {
    date: '',
    lai_cho_vay: '',
    lai_tien_gui: '',
    lai_khac: '',
    phi_lai_tien_gui: '',
    phi_lai_tctd_khac: '',
    phi_hoat_dong: '',
    phi_phat_sinh: '',
    loi_nhuan: ''
  };

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }
  }

}
