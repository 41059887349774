import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UsersIndexService} from "../users-index.service";
import {CommonService} from '../../common.service';

declare let $: any;

@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.css']
})
export class UsersProfileComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private commonService: CommonService) {
  }

  signedUser_thumbnail_full_url = '';

  signedUser_user_id = '';

  signedUser_last_name = '';
  signedUser_first_name = '';
  signedUser_display_name = '';

  signedUser_username = '';
  signedUser_email = '';

  signedUser_address = '';
  signedUser_city = '';
  signedUser_country = '';
  signedUser_postal_code = '';
  signedUser_remark = '';
  signedUser_description = '';

  signedUser_package_title = '';
  signedUser_lbl_expiry_date = '';
  signedUser_subscription_info = '';

  model = {};
  modelPassword = {};

  ngOnInit() {

    this.signedUser_thumbnail_full_url = localStorage.getItem('signedUser_thumbnail_full_url');

    this.signedUser_user_id = localStorage.getItem('signedUser_user_id');

    this.signedUser_last_name = localStorage.getItem('signedUser_last_name');
    this.signedUser_first_name = localStorage.getItem('signedUser_first_name');
    this.signedUser_display_name = localStorage.getItem('signedUser_display_name');

    this.signedUser_username = localStorage.getItem('signedUser_username');
    this.signedUser_email = localStorage.getItem('signedUser_email');

    this.signedUser_address = localStorage.getItem('signedUser_address');
    this.signedUser_city = localStorage.getItem('signedUser_city');
    this.signedUser_country = localStorage.getItem('signedUser_country');
    this.signedUser_postal_code = localStorage.getItem('signedUser_postal_code');
    this.signedUser_remark = localStorage.getItem('signedUser_remark');
    this.signedUser_description = localStorage.getItem('signedUser_description');

    this.signedUser_package_title = localStorage.getItem('signedUser_package_title');
    this.signedUser_lbl_expiry_date = localStorage.getItem('signedUser_lbl_expiry_date');
    this.signedUser_subscription_info = localStorage.getItem('signedUser_subscription_info');

    this.model['id'] = this.signedUser_user_id;
    this.model['last_name'] = this.signedUser_last_name;
    this.model['first_name'] = this.signedUser_first_name;
    this.model['address'] = this.signedUser_address;
    this.model['city'] = this.signedUser_city;
    this.model['country'] = this.signedUser_country;
    this.model['postal_code'] = this.signedUser_postal_code;
    this.model['remark'] = this.signedUser_remark;
    this.model['description'] = this.signedUser_description;

    this.modelPassword['id'] = this.signedUser_user_id;
  }

  onUpdateProfile(): void {
    setTimeout(() => {
      this.userService.updateProfile(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status) {
          localStorage.setItem('signedUser_last_name', resp.content.last_name);
          localStorage.setItem('signedUser_first_name', resp.content.first_name);
          localStorage.setItem('signedUser_display_name', resp.content.display_name);
          localStorage.setItem('signedUser_address', resp.content.address);
          localStorage.setItem('signedUser_city', resp.content.city);
          localStorage.setItem('signedUser_country', resp.content.country);
          localStorage.setItem('signedUser_postal_code', resp.content.postal_code);
          localStorage.setItem('signedUser_remark', resp.content.remark);
          localStorage.setItem('signedUser_description', resp.content.description);


          this.commonService.showMessage({
            message: 'Cập nhật Hồ sơ cá nhân thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }

  onUpdatePassword(): void {
    setTimeout(() => {
      this.userService.updatePassword(this.modelPassword).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status) {
          this.commonService.showMessage({
            message: 'Cập nhật Mật khẩu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }

  onUploadThumbnail(files: FileList): void {
    setTimeout(() => {
      this.userService.updateThumbnail(this.signedUser_user_id, files.item(0)).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status) {
          this.signedUser_thumbnail_full_url = resp.content.thumbnail_full_url;
          localStorage.setItem('signedUser_thumbnail_full_url', resp.content.thumbnail_full_url);

          this.commonService.showMessage({
            message: 'Cập nhật Hình đại diện thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }

}
