import {Component, OnInit, Input} from '@angular/core';
import {NotificationsIndexService} from "../notifications/notifications-index.service";
import {InvitationsIndexService} from "../invitations/invitations-index.service";

@Component({
  selector: 'app-map-layout',
  templateUrl: './map-layout.component.html',
  styleUrls: ['./map-layout.component.css']
})
export class MapLayoutComponent implements OnInit {

  constructor(private notificationService: NotificationsIndexService,
              private invitationService: InvitationsIndexService) { }

  signedUser_user_id = '';
  signedUser_display_name = '';
  signedUser_thumbnail_url = '';

  new_invitations = {};
  new_notifications = {};

  ngOnInit() {
    this.signedUser_user_id = localStorage.getItem('signedUser_user_id');
    this.signedUser_display_name = localStorage.getItem('signedUser_display_name');
    this.signedUser_thumbnail_url = localStorage.getItem('signedUser_thumbnail_url');

    setTimeout(() => {
      this.invitationService.getNew().subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }

        this.new_invitations = resp.content.items;
      })
    }, 50);

    setTimeout(() => {
      this.notificationService.getNew().subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }

        this.new_notifications = resp.content.items;
      })
    }, 50);

  }

}
