import {Component, Input, OnInit} from '@angular/core';
import {LoansService} from '../../loans.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomersService} from '../../../customers/customers.service';
import {AssetsViewComponent} from '../../../assets/popups/assets-view/assets-view.component';
import {AssetsIndexService} from '../../../assets/assets-index.service';

declare let $: any;

@Component({
  selector: 'app-loans-assets',
  templateUrl: './loans-assets.component.html',
  styleUrls: ['./loans-assets.component.css']
})
export class LoansAssetsComponent implements OnInit {

  constructor(private customerService: CustomersService,
              private assetService: AssetsIndexService,
              private loanService: LoansService,
              private commonService: CommonService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  model = {
    asset_id: '',
    asset_type: '',
    image_url: '',
    image_full_url: '',
    title: '',
    value: '',
    giatri: '',
    dinhgia: '',
    attachments: [],
    contact_ids: {},
  };
  @Input()  loan: any;
  @Input()  customer: any;
  @Input()  item: any;
  @Input()  loanItems: any[] = [];
  @Input()  customerItems: any[] = [];

  @Input()
  items: any[] = [];

  @Input()
  attachments: any[] = [];
  dinhgiaCustomer: any;

  customerContacts: any[];
  defaultKyHan: any[];
  contactNumber: any[] = [0];

  ngOnInit() {
    this.defaultKyHan = JSON.parse(localStorage.getItem('defaultKyHan'));
    this.getCustomerContacts(this.loan.customer_id);

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
    setTimeout(() => {
      $('.selectpicker_asset_type').selectpicker();
      $('.selectpicker_stk_ky_han').selectpicker();
    }, 50);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
      if (key === 'dinhgia') {
        this.model.value = $($event.target).val();
      }
      if (key === 'dinhgiaCustomer') {
        this.dinhgiaCustomer = $($event.target).val();
      }
      if (key.slice(0, 10) === 'contact_id') {
        this.model.contact_ids[key] = parseInt($($event.target).val(), 0);
      }
      if (['idCardFSImg', 'idCardBSImg', 'cavetFSImg', 'cavetBSImg'].includes(key)) {
        this.model[key] = $event.target.files.item(0);
      }
    }, 400);
  }

  onAddContactUser(): void {
    this.contactNumber.push(this.contactNumber.length);
    setTimeout(() => {
      $('.selectpicker_contact_id').selectpicker('refresh');
    }, 50);
  }

  onRemoveContactUser(itemIndex): void {
    this.contactNumber.splice(itemIndex, 1);
    delete this.model.contact_ids['contact_id_' + itemIndex];

    setTimeout(() => {
      $('.selectpicker_contact_id').selectpicker('refresh');
    }, 50);
  }

  onSubmit(): void {
    this.loanService.addAsset(this.loan.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.loan = resp.content.loan;
        this.items = resp.content.items;
        // this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }

    });
  }

  onUploadThumbnail(itemId, files: FileList): void {
    setTimeout(() => {
      this.loanService.uploadThumbnail(parseInt(itemId, 0), files.item(0))
        .subscribe(resp => {
          if (!resp) {
            return;
          }
          if (resp.status) {
            this.attachments.push(resp.content);
            this.model.attachments = this.attachments;
            this.model.image_url = resp.content.image_url;
            this.model.image_full_url = resp.content.image_full_url;
            this.commonService.showMessage({
              message: 'Thêm thành công',
              type: 'success'
            });
          } else {
            this.commonService.showMessage({
              message: resp.message.split('\n').join('<br />'),
              type: 'danger'
            });
          }
        });
    }, 50);
  }

  onRemove(assetID): void {
    this.loanService.removeAsset(assetID).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.loanItems = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }
    });
  }


  onClearProperties(data: any): any {
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        data[property] = '';
      }
    }
    return data;
  }


// Old Add Asset to Customer and to Loans
// --------------------------------------
  addToLoanAsset(assetId, title): void {
    this.model = {
      ... this.model,
      asset_id: assetId,
      dinhgia: this.dinhgiaCustomer,
      title,
    };
    this.addLoanAsset();
    this.model = this.onClearProperties(this.model);
  }

  onAddNewAsset(): void {
    this.addCustomerAsset();
  }

  addCustomerAsset(): void {
    this.customerService.addAsset(this.customer.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.customerItems = resp.content.items;
        this.model.asset_id = resp.content.last_asset_id;
        this.addLoanAsset();

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }

    });
  }

  addLoanAsset(): void {
    this.loanService.addAsset(this.loan.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.loanItems = resp.content.items;

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });

        this.model = this.onClearProperties(this.model);
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }
    });
  }

  onRemoveAssetLoan(assetID): void {
    this.loanService.removeAsset(assetID).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.loanItems = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }
    });
  }

  getRoundNumber(val): number {
    return Math.round(val);
  }

  getCustomerContacts(customerId): void {
    this.customerService.getContacts(customerId)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.customerContacts = resp.content.items;
        }
        setTimeout(() => {
          $('.selectpicker_contact_id').selectpicker('refresh');
        }, 50);

      });
  }

  modalView(itemId): void {
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    });
  }


// End Old Add Asset to Customer and to Loans
// --------------------------------------
}
