import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomersService} from '../../../customers/customers.service';
import {CommonService} from '../../../common.service';
import {ReportsIndexService} from '../../reports-index.service';
import * as moment from 'moment';

declare let $: any;

@Component({
  selector: 'app-report-capital-add',
  templateUrl: './report-capital-add.component.html',
  styleUrls: ['./report-capital-add.component.css']
})
export class ReportCapitalAddComponent implements OnInit {

  constructor(private customersService: CustomersService,
              private reportService: ReportsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  @Input()
  modal_action: any;

  model = {
    date: moment(new Date()).format('DD/MM/YYYY'),
    lbl_date: moment(new Date()).format('DD/MM/YYYY'),
    tien_gui: '',
    short_tg_kkh: '',
    short_tctd_khac: '',
    long_dieu_le: '',
    tu_co: '',
    long_tgtk_con_lai: '',
    long_vay_nhht: '',
    lai_suat_dai_han: '',
    an_toan_von_min: '',
    du_no_khong_cam_co: '',
    du_no_cho_vay: '',
    du_no: '',
    nhom_1: '',
    nhom_2: '',
    nhom_2_ss: '',
    nhom_3: '',
    nhom_3_ss: '',
    nhom_4: '',
    nhom_4_ss: '',
    nhom_5: '',
    nhom_5_ss: '',
    customer_nhom_1: '',
    customer_nhom_2: '',
    customer_nhom_2_ss: '',
    customer_nhom_3: '',
    customer_nhom_3_ss: '',
    customer_nhom_4: '',
    customer_nhom_4_ss: '',
    customer_nhom_5: '',
    customer_nhom_5_ss: '',
    gui_tctd_khac: '',
    gui_tctd_khac_co_kh: '',
  };

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
      this.model.date = this.item.lbl_date;

      if (this.modal_action === 'add') {
        this.model.date = moment(new Date()).format('DD/MM/YYYY');
        this.model.lbl_date = moment(new Date()).format('DD/MM/YYYY');
      }
    }
  }

  onSubmit(): void {
    if (this.modal_action === 'add') {
      this.onNew();
      return;
    }
    this.onUpdate();
  }

  onNew(): void {
    this.reportService.createCapital(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.items.unshift(resp.content.item);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  onUpdate(): void {
    this.reportService.createCapital(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.item = resp.content.item;
          this.activeModal.close();

          _.remove(this.items, (loopItem) => {
            return loopItem.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }
}
