import {Component, Input, OnInit} from '@angular/core';
import {CustomersService} from '../../customers.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-customers-contacts',
  templateUrl: './customers-contacts.component.html',
  styleUrls: ['./customers-contacts.component.css']
})
export class CustomersContactsComponent implements OnInit {

  constructor(private customerService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model: any = {
    birthdate_year: ''
  };

  @Input()
  customer: any;

  @Input()
  items: any[] = [];

  customers = [];

  ngOnInit() {
    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_gender').selectpicker('refresh');
      $('.selectpicker_customer_id').selectpicker('refresh');
    }, 50);
  }

  selectCustomer(event) {
    const id = event.target.value;
    const selectedCustomer = this.customers.find(c => c.id == id);

    this.model.title = selectedCustomer.title;
    this.model.lbl_birthdate = selectedCustomer.lbl_birthdate;
    this.model.gender = selectedCustomer.gender.toString();
    this.model.id_no = selectedCustomer.id_no;
    this.model.lbl_id_issued_date = selectedCustomer.lbl_id_issued_date;
    this.model.id_issued_place = selectedCustomer.id_issued_place;
    this.model.phone = selectedCustomer.mobile;
    this.model.email = selectedCustomer.user_email;
    this.model.address = selectedCustomer.primary_address;
    this.model.career = selectedCustomer.career;

    setTimeout(() => {
      $('.selectpicker_gender').selectpicker('refresh');
    }, 50);
  }

  getListCustomers(searchTerm: string): void {
    this.customerService.getList({status: -1, s: searchTerm, page: 0})
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.customers = resp.content.items;
        }
        setTimeout(() => {
          $('.selectpicker_customer_id').selectpicker('refresh');
        }, 50);

      });
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();

      if (key === 'customer_s') {
        this.getListCustomers($($event.target).val());
      }

      if (key === 'lbl_birthdate') {
        const date = $($event.target).val();
        if (date.slice(0, 5) === '01/01') {
          this.model.birthdate_year = date.slice(6, 11);
          console.log(this.model);
        }
      }
    }, 400);
  }

  onSubmit(): void {
    this.customerService.addContact(this.customer.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.customer = resp.content.customer;
        this.items = resp.content.items;
        this.model = this.onClearProperties(this.model);

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }

    });
  }

  onRemove(id): void {
    this.customerService.removeContact(id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.customer = resp.content.customer;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }
    });
  }

  // clear object
  onClearProperties(data: any): any {
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        data[property] = '';
      }
    }
    return data;
  }
}
