import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-report-capital-view',
  templateUrl: './report-capital-view.component.html',
  styleUrls: ['./report-capital-view.component.css']
})
export class ReportCapitalViewComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  @Input()
  item: any;

  model = {
    day1: {
      id: '',
      tien_gui: '',
      short_tg_kkh: '',
      short_tctd_khac: '',
      long_dieu_le: '',
      long_vay_nhht: '',
      long_tgtk_con_lai: '',
      tu_co: '',
      lai_suat_dai_han: '',
      du_no_cho_vay: '',
      an_toan_von_min: '',
      du_no_khong_cam_co: '',
      du_no: '',
      nhom_1: '',
      nhom_2: '',
      nhom_2_ss: '',
      nhom_3: '',
      nhom_3_ss: '',
      nhom_4: '',
      nhom_4_ss: '',
      nhom_5: '',
      nhom_5_ss: '',
      gui_tctd_khac: '',
      gui_tctd_khac_co_kh: '',
      created_at: '',
      modified_by: '',
      modified_at: '',
      short_tg_con_lai: '',
      short_total: '',
      long_total: '',
      du_no_cho_vay_max: '',
      du_no_cho_vay_max_group: '',
      short_cho_vay_max: '',
      short_da_sd: '',
      short_ty_le: '',
      short_con_lai: '',
      no_qua_han: '',
      no_qua_han_so_sach: '',
      no_xau: '',
      no_xau_so_sach: '',
      qua_han_du_no: '',
      qua_han_du_no_so_sach: '',
      no_xau_du_no: '',
      no_xau_du_no_so_sach: '',
      gui_tctd_khac_khong_kh: '',
      date: '',
      lbl_date: '',
    },
    day2: {
      id: '',
      tien_gui: '',
      short_tg_kkh: '',
      short_tctd_khac: '',
      long_dieu_le: '',
      long_vay_nhht: '',
      long_tgtk_con_lai: '',
      tu_co: '',
      lai_suat_dai_han: '',
      du_no_cho_vay: '',
      an_toan_von_min: '',
      du_no_khong_cam_co: '',
      du_no: '',
      nhom_1: '',
      nhom_2: '',
      nhom_2_ss: '',
      nhom_3: '',
      nhom_3_ss: '',
      nhom_4: '',
      nhom_4_ss: '',
      nhom_5: '',
      nhom_5_ss: '',
      gui_tctd_khac: '',
      gui_tctd_khac_co_kh: '',
      created_at: '',
      modified_by: '',
      modified_at: '',
      short_tg_con_lai: '',
      short_total: '',
      long_total: '',
      du_no_cho_vay_max: '',
      du_no_cho_vay_max_group: '',
      short_cho_vay_max: '',
      short_da_sd: '',
      short_ty_le: '',
      short_con_lai: '',
      no_qua_han: '',
      no_qua_han_so_sach: '',
      no_xau: '',
      no_xau_so_sach: '',
      qua_han_du_no: '',
      qua_han_du_no_so_sach: '',
      no_xau_du_no: '',
      no_xau_du_no_so_sach: '',
      gui_tctd_khac_khong_kh: '',
      date: '',
      lbl_date: '',
    },
    compare: {
      tien_gui: '',
      short_tg_kkh: '',
      short_tctd_khac: '',
      long_dieu_le: '',
      long_vay_nhht: '',
      long_tgtk_con_lai: '',
      tu_co: '',
      lai_suat_dai_han: '',
      du_no_cho_vay: '',
      an_toan_von_min: '',
      du_no_khong_cam_co: '',
      du_no: '',
      nhom_1: '',
      nhom_2: '',
      nhom_2_ss: '',
      nhom_3: '',
      nhom_3_ss: '',
      nhom_4: '',
      nhom_4_ss: '',
      nhom_5: '',
      nhom_5_ss: '',
      gui_tctd_khac: '',
      gui_tctd_khac_co_kh: '',
      short_tg_con_lai: '',
      short_total: '',
      long_total: '',
      du_no_cho_vay_max: '',
      du_no_cho_vay_max_group: '',
      short_cho_vay_max: '',
      short_da_sd: '',
      short_ty_le: '',
      short_con_lai: '',
      no_qua_han: '',
      no_qua_han_so_sach: '',
      no_xau: '',
      no_xau_so_sach: '',
      qua_han_du_no: '',
      qua_han_du_no_so_sach: '',
      no_xau_du_no: '',
      no_xau_du_no_so_sach: '',
      gui_tctd_khac_khong_kh: '',
    },
    phat_sinh: {
      nhom_1: '',
      nhom_2: '',
      nhom_2_ss: '',
      nhom_3: '',
      nhom_3_ss: '',
      nhom_4: '',
      nhom_4_ss: '',
      nhom_5: '',
      nhom_5_ss: ''
    }
  };

  ngOnInit() {
    if (this.item) {
      if (this.item.compare) {
        this.model.compare = this.item.compare;
      }
      if (this.item.phat_sinh) {
        this.model.phat_sinh = this.item.phat_sinh;
      }
      if (this.item.day1) {
        this.model.day1 = this.item.day1;
      }
      if (this.item.day2) {
        this.model.day2 = this.item.day2;
      }
    }
  }

}
