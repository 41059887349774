import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CommonService} from '../../../common.service';
import {AssetsIndexService} from '../../assets-index.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-assets-baohiems',
  templateUrl: './assets-baohiems.component.html',
  styleUrls: ['./assets-baohiems.component.css']
})

export class AssetsBaohiemsComponent implements OnInit {

  constructor(private assetService: AssetsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {};

  @Input()
  asset: any;

  @Input()
  items: any[] = [];

  ngOnInit() {
    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $(".selectpicker_gender").selectpicker('refresh');
    }, 50);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onSubmit(): void {
    this.assetService.addBaohiem(this.asset.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.asset = resp.content.asset;
        this.items = resp.content.items;
        this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }

    })
  }

  onRemove(id): void {
    this.assetService.removeBaohiem(id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.asset = resp.content.asset;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }


    })
  }

}
