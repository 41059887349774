import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../common.service';
import * as _ from 'lodash';
import {SettingsIndexService} from '../../settings-index.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-settings-add-general',
  templateUrl: 'settings-add-general.component.html',
  styleUrls: ['settings-add-general.component.css']
})
export class SettingsAddGeneralComponent implements OnInit {

  constructor(private settingsService: SettingsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    title: '',
    value: ''
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }

    setTimeout(() => {
      $('.datepicker').datetimepicker({
      useCurrent: false,
        format: 'DD/MM/YYYY',
        icons: {
          time: 'fa fa-clock-o',
          date: 'fa fa-calendar',
          up: 'fa fa-chevron-up',
          down: 'fa fa-chevron-down',
          previous: 'fa fa-chevron-left',
          next: 'fa fa-chevron-right',
          today: 'fa fa-screenshot',
          clear: 'fa fa-trash',
          close: 'fa fa-remove'
        }
      });
    }, 10);

  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onSubmit(): void {
    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.settingsService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.activeModal.close();
          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  onUpdate(): void {
    this.settingsService.updateItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1){
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

}
