import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {

  constructor(private http: HttpClient) { }

  getList(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('template', filterParams.template)
      .set('customer_id', filterParams.customer_id)
      .set('status', filterParams.status)
      .set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}reminders/list/p/${filterParams.page}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/detail/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  createItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/create?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/update/id/${model.id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateStatus(id: any, status): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/update_status/id/${id}/status/${status}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  sendSMS(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/send_sms/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  sendEmail(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/send_email/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  deleteItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/delete/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getComments(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/comments/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addComment(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/add_comment/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeComment(id: any, comment_id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}reminders/remove_comment/id/${id}/comment_id/${comment_id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  download(id): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}reminders/download/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    window.open(url, '_blank');
    return;
  }
}
