import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {CommonService} from '../../common.service';
import * as moment from 'moment';
import {ReportsIndexService} from '../reports-index.service';

declare let $: any;
declare let md: any;
@Component({
  selector: 'app-reports-business',
  templateUrl: 'reports-business.component.html',
  styleUrls: ['reports-business.component.css']
})
export class ReportsBusinessComponent implements OnInit {

  constructor(private reportService: ReportsIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
    time_period: '0',
    filter_from_date: moment(new Date()).format('YYYY-MM-DD'),
    filter_to_date: moment(new Date()).format('YYYY-MM-DD'),
    filter_select_month: null,
    filter_select_year: null,
    filter_select_quarter: null,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  report = {
    customer_report: {
      total_customer_potentials: 0,
      total_customer_news: 0,
      total_customer_has_loans: 0,
      total_customer_has_more_loans: 0
    },
    loan_report: {
      total_loan_news: 0,
      total_loan_approveds: 0,
      total_loan_approvals: 0,
      total_loan_rejecteds: 0,
      total_loan_risks: 0,
      total_loan_restructureds: 0
    },
    payment_report: {
      total_paid_in_period: 0,
      total_paid_in_period_in_3: 0,
      total_paid_in_period_1_to_14: 0,
      total_paid_in_period_over_14: 0,
      total_paid_in_period_over_19: 0
    },
    asset_report: {
      total_car: 0,
      total_house: 0,
      total_sotietkiem: 0,
      total_other: 0
    },
    loan_amt_report: {
      more_loans: 0,
      new_loans: 0,
      approval_loans: 0,
      approved_loans: 0,
      rejected_loans: 0,
      risk_loans: 0,
      paid_in_period: 0,
      paid_in_period_in_3: 0,
      paid_in_period_1_to_14: 0,
      paid_in_period_over_14: 0,
      paid_in_period_over_19: 0
    },
    asset_amt_report: {
      amt_car: 0,
      amt_house: 0,
      amt_sotietkiem: 0,
      amt_other: 0
    }
  };
  loading = true;

  ngOnInit() {
    this.onChangeTimePeriod();
    this.loadList();

    setTimeout(() => {
      $('.filter_time_period_selectpicker').selectpicker();
      $('.filter_select_quarter').selectpicker('refresh');
    }, 50);
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = $($event.target).val();
    }, 50);

    this.onChangeTimePeriod();
  }

  onChangeTimePeriod(): void {
    setTimeout(() => {
      $('.filter_select_quarter').selectpicker('refresh');
    }, 50);

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'YYYY-MM-DD',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
    $('#filter_select_month').datetimepicker({
      format: 'MM',
      dayViewHeaderFormat: 'MMMM',
      viewMode: 'months'
    });
    $('#filter_select_year').datetimepicker({
      format: 'YYYY',
      dayViewHeaderFormat: 'YYYY',
      viewMode: 'years'
    });
  }

  loadList(): void {
    setTimeout(() => {
      this.reportService.getReportBusiness(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.report = resp.content;
      });
    }, 50);
  }

  onFilterSubmit(): void {
    this.loading = true;

    const timePeriod = parseInt(this.filterParams.time_period, 0);
    const year = parseInt(this.filterParams.filter_select_year, 0);
    const month = parseInt(this.filterParams.filter_select_month, 0) - 1;
    const quarter = parseInt(this.filterParams.filter_select_quarter, 0);
    // tslint:disable-next-line:one-variable-per-declaration variable-name
    let from_time: any, to_time: any;

    switch (timePeriod) {
      case 1:
        // Month
        from_time = new Date(year, month, 1);
        to_time = new Date(year, month + 1, 1);
        break;
      case 2:
        // Quarter
        from_time = new Date(year, quarter, 1);
        to_time = new Date(year, (quarter + 1) * 3, 1);
        break;
      case 3:
        // Year
        from_time = new Date(year, 0, 1);
        to_time = new Date(year + 1, 0, 1);
        break;
      default:
        from_time = this.filterParams.filter_from_date;
        to_time = this.filterParams.filter_to_date;
    }

    this.filterParams.filter_from_date = moment(from_time).format('YYYY-MM-DD');
    this.filterParams.filter_to_date = moment(to_time).format('YYYY-MM-DD');
    console.log(this.filterParams);

    this.loadList();
  }
}
