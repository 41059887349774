import {Component, OnInit} from '@angular/core';
import {AssetsIndexService} from '../assets-index.service';
import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {PopupComponent} from '../../_shared/popup/popup.component';
import {CommonService} from '../../common.service';

import {AssetsAddInsurancesComponent} from '../popups/assets-add-insurances/assets-add-insurances.component';
import {AssetsViewComponent} from '../popups/assets-view/assets-view.component';
import {UsersIndexService} from '../../users/users-index.service';
import {LoansService} from '../../loans/loans.service';
import {CustomersService} from '../../customers/customers.service';
import {AssetsUsersComponent} from '../popups/assets-users/assets-users.component';
import {AssetsCommentsComponent} from '../popups/assets-comments/assets-comments.component';
import {AssetsDangkiemsComponent} from '../popups/assets-dangkiems/assets-dangkiems.component';
import {AssetsBaohiemsComponent} from '../popups/assets-baohiems/assets-baohiems.component';

declare let $: any;

@Component({
  selector: 'app-assets-insurances',
  templateUrl: './assets-insurances.component.html',
  styleUrls: ['./assets-insurances.component.css']
})
export class AssetsInsurancesComponent implements OnInit {

  constructor(private assetService: AssetsIndexService,
              private loanService: LoansService,
              private customerService: CustomersService,
              private userService: UsersIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) {
  }

  filterParams = {
    is_self_created: -1,
    is_following: -1,
    status: 0,
    s: '',
    customer_id: 0,
    loan_id: 0,
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: any[] = [];

  customers: any[] = [];
  loans: any[] = [];
  loading = true;

  permissionSettings: any;

  ngOnInit() {
    this.userService.validateAccessToken();

    this.loadList();
    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));

    this.customerService.getList({
      is_personal: 0,
      is_self_created: -1,
      status: -1,
      s: '',
      page: 0,
    }).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.customers = resp.content.items;

      $(document).ready(function() {
        $('.filter_selectpicker').selectpicker();
      });
    });

    this.loadLoans(0);
  }

  filterList(): void {
    this.filterParams.page = 1;
    this.loadList();
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = parseInt($($event.target).val());
      if (key == 'customer_id') {
        this.filterParams.loan_id = 0;
        this.loadLoans(this.filterParams[key]);
      }
      if (key == 'filter_condition') {
        switch (this.filterParams[key]) {
          case 0: // Tất cả
            this.filterParams.is_self_created = -1;
            this.filterParams.is_following = -1;
            this.filterParams.status = 0;
            break;
          case 1: // Việc tự tạo
            this.filterParams.is_self_created = 1;
            this.filterParams.is_following = -1;
            this.filterParams.status = 0;
            break;
          case 2: // Việc được giao
            this.filterParams.is_self_created = 0;
            this.filterParams.is_following = -1;
            this.filterParams.status = 0;
            break;
          case 3: // Việc đang theo dõi
            this.filterParams.is_self_created = -1;
            this.filterParams.is_following = 1;
            this.filterParams.status = 0;
            break;
          case 4: // Đã xong
            this.filterParams.is_self_created = -1;
            this.filterParams.is_following = -1;
            this.filterParams.status = 1;
            break;
          default:
            break;
        }
      }
      this.loadList();
    }, 400);
  }

  loadLoans(customer_id): void {
    setTimeout(() => {
      this.loanService.getList({
        is_self_created: -1,
        is_following: -1,
        status: 0,
        s: '',
        customer_id,
        page: 0,
      }).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }

        this.loans = resp.content.items;

        setTimeout(() => {
          $('.filter_selectpicker_loan').selectpicker('refresh');
        }, 50);
      });
    }, 50);
  }

  loadList(): void {
    setTimeout(() => {
      this.assetService.getListInsurances(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
        this.total = resp.content.total;
        this.ppp = resp.content.ppp;
      });
    }, 50);
  }

  modalUsers(itemId): void {
    this.assetService.getUsers(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsUsersComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.asset = resp.content.asset;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalBaohiems(itemId): void {
    this.assetService.getBaohiems(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsBaohiemsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.asset = resp.content.asset;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalComments(itemId): void {
    this.assetService.getComments(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsCommentsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.asset = resp.content.asset;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalView(itemId): void {
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    });
  }

  modalAdd() {
    const modalRef = this.modalService.open(AssetsAddInsurancesComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.modal_action = 'add';
    modalRef.componentInstance.modal_title = 'Thêm tài sản thế chấp';
    modalRef.componentInstance.items = this.items;
  }

  modalUpdate(itemId) {
    this.assetService.getInsurance(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsAddInsurancesComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.modal_action = 'update';
      modalRef.componentInstance.modal_title = `<strong>${resp.content.item.title}</strong>`;
      modalRef.componentInstance.items = this.items;
      modalRef.componentInstance.item = resp.content.item;
    });

  }

  modalFeatured(itemId, isFeatured) {
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Thay đổi Top';
      modalRef.componentInstance.description = `Bạn có muốn thay đổi Top cho tài sản <strong>${resp.content.item.lbl_id}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.assetService.updateFeatured(itemId, isFeatured).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          this.loadList();

          this.commonService.showMessage({
            message: 'Cập nhật thành công',
            type: 'success',
          });
        });
      });
    });

  }

  modalDelete(itemId) {
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Xóa dữ liệu';
      modalRef.componentInstance.description = `Bạn có muốn xóa ô tô <strong>${resp.content.item.lbl_id}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.assetService.deleteItem(itemId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          _.remove(this.items, (loop_item) => {
            return loop_item.id === itemId;
          });

          this.commonService.showMessage({
            message: 'Dữ liệu đã được xóa khỏi hệ thống',
            type: 'success',
          });
        });
      });
    });

  }

}
