import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {CustomersService} from "../../../customers/customers.service";
import {CommonService} from "../../../common.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {RemindersService} from "../../reminders.service";

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-reminders-add',
  templateUrl: './reminders-add.component.html',
  styleUrls: ['./reminders-add.component.css']
})
export class RemindersAddComponent implements OnInit {

  constructor(private apiService: RemindersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {};

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }

    this.initMaterialWizard();

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-chevron-up",
        down: "fa fa-chevron-down",
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $(".selectpicker_customer_id").selectpicker('refresh');
      $(".selectpicker_contact_dong_trachnhiem_1").selectpicker('refresh');
      $(".selectpicker_contact_dong_trachnhiem_2").selectpicker('refresh');
    }, 50);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1){
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1){
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  initMaterialWizard() {
    $('.card-wizard').bootstrapWizard({
      'tabClass': 'nav nav-pills',
      'nextSelector': '.btn-next',
      'previousSelector': '.btn-previous',

      onPrevious: function (tab, navigation, index) {
      },

      onNext: function (tab, navigation, index) {
      },

      onInit: function (tab, navigation, index) {

      },

      onTabClick: function (tab, navigation, index) {
      },

      onTabShow: function (tab, navigation, index) {

      }
    });

    setTimeout(function () {
      $('.card.card-wizard').addClass('active');
    }, 600);

    function refreshAnimation($wizard, index) {
      var $total = $wizard.find('.nav li').length;
      var $li_width = 100 / $total;

      var total_steps = $wizard.find('.nav li').length;
      var move_distance = $wizard.width() / total_steps;
      var index_temp = index;
      var vertical_level = 0;

      var mobile_device = $(document).width() < 600 && $total > 3;

      if (mobile_device) {
        move_distance = $wizard.width() / 2;
        index_temp = index % 2;
        $li_width = 50;
      }

      $wizard.find('.nav li').css('width', $li_width + '%');

      var step_width = move_distance;
      move_distance = move_distance * index_temp;

      var $current = index + 1;

      if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
        move_distance -= 8;
      } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
        move_distance += 8;
      }

      if (mobile_device) {
        vertical_level = index / 2;
        vertical_level = vertical_level * 38;
      }

      $wizard.find('.moving-tab').css('width', step_width);
      $('.moving-tab').css({
        'transform': 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        'transition': 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

      });
    }
  }

}
