import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {CommonService} from '../../common.service';
import {ReportsIndexService} from '../reports-index.service';
import {ReportBriefViewComponent} from '../popups/report-brief-view/report-brief-view.component';



@Component({
  selector: 'app-reports-brief-evaluation',
  templateUrl: 'reports-brief-evaluation.component.html',
  styleUrls: ['reports-brief-evaluation.component.css']
})
export class ReportsBriefEvaluationComponent implements OnInit {

  constructor(private reportService: ReportsIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: {
    'id': '',
    'lbl_id': '',
    'amt': any,
    'title_payment': '-',
    'tong_goc_da_thu': 0,
    'tong_lai_da_thu': 0,
    'du_no_goc': any,
    'lai_du_thu': 0,
    'tong_khoan_thanh_toan': 0,
    'count_tong_khoan_thanh_toan': 0,
    'tong_khoan_tra_dung_han': 0,
    'count_tong_khoan_tra_dung_han': '0',
    'tong_thanh_toan_tre': 0,
    'count_tong_thanh_toan_tre': '0',
    'so_ngay_tre_han': 0,
    'tong_nguy_co_mat_von': any,
    'lai_du_kien': any
  }[] = [];
  loading = true;

  ngOnInit() {
    this.loadList();

  }

  filterList(): void {
  }

  loadList(): void {
    setTimeout(() => {
      this.reportService.getReportBrief(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
      });
    }, 50);
  }

  modalReportBrief(item): void {
    const modalRef = this.modalService.open(ReportBriefViewComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.item = item;
  }
}
