import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotificationsIndexComponent} from './notifications/notifications-index/notifications-index.component';
import {GroupsIndexComponent} from './groups/groups-index/groups-index.component';
import {UsersIndexComponent} from './users/users-index/users-index.component';
import {UsersSigninComponent} from './users/users-signin/users-signin.component';
import {UsersSignoutComponent} from './users/users-signout/users-signout.component';
import {SettingsIndexComponent} from './settings/settings-index/settings-index.component';
import {SettingsGeneralComponent} from './settings/settings-general/settings-general.component';
import {AppLayoutComponent} from './app-layout/app-layout.component';
import {AccountLayoutComponent} from './account-layout/account-layout.component';
import {UsersSignUpComponent} from './users/users-sign-up/users-sign-up.component';
import {UsersForgotPasswordComponent} from './users/users-forgot-password/users-forgot-password.component';
import {UsersProfileComponent} from './users/users-profile/users-profile.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AssetsIndexComponent} from './assets/assets-index/assets-index.component';
import {ReportsBusinessComponent} from './reports/reports-business/reports-business.component';
import {ReportsBriefEvaluationComponent} from './reports/reports-brief-evaluation/reports-brief-evaluation.component';
import {ReportsAssetEvaluationComponent} from './reports/reports-asset-evaluation/reports-asset-evaluation.component';
import {ReportsClientEvaluationComponent} from './reports/reports-client-evaluation/reports-client-evaluation.component';
import {ReportsBusinessChartComponent} from './reports/reports-business-chart/reports-business-chart.component';
import {ReportsEmployeeActivityComponent} from './reports/reports-employee-activity/reports-employee-activity.component';
import {CustomersPotentialComponent} from './customers/customers-potential/customers-potential.component';
import {CustomersHasLoansComponent} from './customers/customers-has-loans/customers-has-loans.component';
import {CustomersBadCreditComponent} from './customers/customers-bad-credit/customers-bad-credit.component';
import {CustomersMembersComponent} from './customers/customers-members/customers-members.component';
import {LoanApplicationsComponent} from './loans/loan-applications/loan-applications.component';
import {LoanApprovalComponent} from './loans/loan-approval/loan-approval.component';
import {CreditEvaluationComponent} from './loans/credit-evaluation/credit-evaluation.component';
import {ApprovedLoansComponent} from './loans/approved-loans/approved-loans.component';
import {RiskLoansComponent} from './loans/risk-loans/risk-loans.component';
import {RestructuredLoansComponent} from './loans/restructured-loans/restructured-loans.component';
import {PaidOffLoansComponent} from './loans/paid-off-loans/paid-off-loans.component';
import {MapLayoutComponent} from './map-layout/map-layout.component';
import {AssetsDashboardComponent} from './assets/assets-dashboard/assets-dashboard.component';
import {AssetsMapComponent} from './assets/assets-map/assets-map.component';
import {AssetsRegistrationsComponent} from './assets/assets-registrations/assets-registrations.component';
import {AssetsInsurancesComponent} from './assets/assets-insurances/assets-insurances.component';
import {AssetsCavetComponent} from './assets/assets-cavet/assets-cavet.component';
import {UsersPermissionsComponent} from './users/users-permissions/users-permissions.component';
import {LoanRemindersComponent} from './loans/loan-reminders/loan-reminders.component';
import {LoanRefusalComponent} from './loans/loan-refusal/loan-refusal.component';
import {DisbursedLoansComponent} from './loans/disbursed-loans/disbursed-loans.component';
import {ReportsCapitalComponent} from './reports/reports-capital/reports-capital.component';
import {ReportsIncomeComponent} from './reports/reports-income/reports-income.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      { path: '', component: DashboardComponent}, // DashboardComponent, HomeIndexComponent },

      { path: 'home/index', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'loans/reminders', component: LoanRemindersComponent },

      { path: 'customers/potential', component: CustomersPotentialComponent },
      { path: 'customers/has-loans', component: CustomersHasLoansComponent },
      { path: 'customers/bad-credit', component: CustomersBadCreditComponent },
      { path: 'customers/members', component: CustomersMembersComponent },

      { path: 'loans/applications', component: LoanApplicationsComponent },
      { path: 'loans/credit-evaluation', component: CreditEvaluationComponent },
      { path: 'loans/approval', component: LoanApprovalComponent },
      { path: 'loans/approved', component: ApprovedLoansComponent },
      { path: 'loans/disbursed', component: DisbursedLoansComponent },
      { path: 'loans/risk', component: RiskLoansComponent },
      { path: 'loans/restructured', component: RestructuredLoansComponent },
      { path: 'loans/paid-off', component: PaidOffLoansComponent },
      { path: 'loans/refusal', component: LoanRefusalComponent },

      { path: 'assets/index', component: AssetsIndexComponent },
      { path: 'assets/dashboard', component: AssetsDashboardComponent },
      { path: 'assets/registrations', component: AssetsRegistrationsComponent },
      { path: 'assets/insurances', component: AssetsInsurancesComponent },
      { path: 'assets/cavet', component: AssetsCavetComponent },

      { path: 'reports/business', component: ReportsBusinessComponent },
      { path: 'reports/capital', component: ReportsCapitalComponent },
      { path: 'reports/income', component: ReportsIncomeComponent },
      { path: 'reports/brief_evaluation', component: ReportsBriefEvaluationComponent },
      { path: 'reports/asset_evaluation', component: ReportsAssetEvaluationComponent },
      { path: 'reports/client_evaluation', component: ReportsClientEvaluationComponent },
      { path: 'reports/business_chart', component: ReportsBusinessChartComponent },
      { path: 'reports/employee_activity', component: ReportsEmployeeActivityComponent },

      { path: 'groups/index', component: GroupsIndexComponent },
      { path: 'users/index', component: UsersIndexComponent },
      { path: 'users/permissions', component: UsersPermissionsComponent },

      { path: 'notifications/index', component: NotificationsIndexComponent },
      { path: 'settings/index', component: SettingsIndexComponent },
      { path: 'settings/general', component: SettingsGeneralComponent },
      { path: 'users/profile', component: UsersProfileComponent },
    ]
  },

  {
    path: '',
    component: AccountLayoutComponent,
    children: [
      { path: 'users/signin', component: UsersSigninComponent},
      { path: 'users/signout', component: UsersSignoutComponent },
      { path: 'users/signup', component: UsersSignUpComponent },
      { path: 'users/forgot_password', component: UsersForgotPasswordComponent},
    ]
  },

  {
    path: '',
    component: MapLayoutComponent,
    children: [
      { path: 'assets/map', component: AssetsMapComponent },
    ]
  },

  /*{
    path: '',
    component: AppSurveyLayoutComponent,
    children: [
      { path: 'surveys/record/:survey_code', component: SurveysRecordComponent },
    ]
  },*/

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
