import {Injectable} from '@angular/core';

declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() {
  }

  showMessage(params) {
    if (!params.type) {
      params.type = 'info';
    }

    if (!params.time) {
      params.time = 500;
    }

    if (!params.delay) {
      params.delay = 2000;
    }

    if (!params.z_index) {
      params.z_index = 1600;
    }

    $.notify({
      icon: "notifications",
      message: params.message

    }, {
      type: params.type,
      timer: params.time,
      delay: params.delay,
      z_index: params.z_index,
      placement: {
        from: 'top',
        align: 'right'
      }
    });
  }

  showSuccessMessage(): void {
    this.showMessage({
      message: 'Cập nhật dữ liệu thành công',
      type: 'success'
    });
  }

  showDeletedMessage(): void {
    this.showMessage({
      message: 'Dữ liệu đã được xóa khỏi hệ thống',
      type: 'success'
    });
  }
}
