import {Component, Input, OnInit} from '@angular/core';
import {CustomersService} from "../../customers.service";
import {CommonService} from "../../../common.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-customers-loans',
  templateUrl: './customers-loans.component.html',
  styleUrls: ['./customers-loans.component.css']
})
export class CustomersLoansComponent implements OnInit {

  constructor(private customerService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) {
  }

  model = {};

  @Input()
  customer: any;

  @Input()
  items: any[] = [];

  ngOnInit() {

  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

}
