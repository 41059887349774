import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../common.service';
import {UsersIndexService} from '../../users/users-index.service';

import * as _ from 'lodash';

import {GroupsIndexService} from '../groups-index.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-groups-add',
  templateUrl: 'groups-add.component.html',
  styleUrls: ['groups-add.component.css'],
  providers: [GroupsIndexService, CommonService]
})
export class GroupsAddComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private apiService: GroupsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  permissionSettings = {
    customer_potential_create: false,
    customer_potential_view: false,
    customer_potential_edit: false,
    customer_potential_delete: false,

    customer_hssv_create: false,
    customer_hssv_view: false,
    customer_hssv_edit: false,
    customer_hssv_delete: false,

    customer_loan_outdate_create: false,
    customer_loan_outdate_view: false,
    customer_loan_outdate_edit: false,
    customer_loan_outdate_delete: false,

    customer_client_create: false,
    customer_client_view: false,
    customer_client_edit: false,
    customer_client_delete: false,

    brief_dxvv_create: false,
    brief_dxvv_view: false,
    brief_dxvv_edit: false,
    brief_dxvv_delete: false,
    brief_dxvv_confirm_payment: false,
    brief_dxvv_schedule: false,
    brief_dxvv_sent: false,
    brief_dxvv_checked: false,

    brief_xhtd_create: false,
    brief_xhtd_view: false,
    brief_xhtd_edit: false,
    brief_xhtd_delete: false,
    brief_xhtd_confirm_payment: false,
    brief_xhtd_schedule: false,
    brief_xhtd_sent: false,
    brief_xhtd_checked: false,

    brief_approvement_create: false,
    brief_approvement_view: false,
    brief_approvement_edit: false,
    brief_approvement_delete: false,
    brief_approvement_confirm_payment: false,
    brief_approvement_schedule: false,
    brief_approvement_sent: false,
    brief_approvement_checked: false,

    brief_hs_giai_ngan_create: false,
    brief_hs_giai_ngan_view: false,
    brief_hs_giai_ngan_edit: false,
    brief_hs_giai_ngan_delete: false,
    brief_hs_giai_ngan_confirm_payment: false,
    brief_hs_giai_ngan_schedule: false,
    brief_hs_giai_ngan_sent: false,
    brief_hs_giai_ngan_checked: false,

    brief_hs_co_no_qua_han_create: false,
    brief_hs_co_no_qua_han_view: false,
    brief_hs_co_no_qua_han_edit: false,
    brief_hs_co_no_qua_han_delete: false,
    brief_hs_co_no_qua_han_confirm_payment: false,
    brief_hs_co_no_qua_han_schedule: false,
    brief_hs_co_no_qua_han_sent: false,
    brief_hs_co_no_qua_han_checked: false,

    brief_hs_co_cau_create: false,
    brief_hs_co_cau_view: false,
    brief_hs_co_cau_edit: false,
    brief_hs_co_cau_delete: false,
    brief_hs_co_cau_confirm_payment: false,
    brief_hs_co_cau_schedule: false,
    brief_hs_co_cau_sent: false,
    brief_hs_co_cau_checked: false,

    brief_finished_create: false,
    brief_finished_view: false,
    brief_finished_edit: false,
    brief_finished_delete: false,
    brief_finished_confirm_payment: false,
    brief_finished_schedule: false,
    brief_finished_sent: false,
    brief_finished_checked: false,

    brief_notify_bank_create: false,
    brief_notify_bank_view: false,
    brief_notify_bank_edit: false,
    brief_notify_bank_delete: false,
    brief_notify_bank_confirm_payment: false,
    brief_notify_bank_schedule: false,
    brief_notify_bank_sent: false,
    brief_notify_bank_checked: false,

    asset_list_create: false,
    asset_list_view: false,
    asset_list_edit: false,
    asset_list_delete: false,

    asset_history_create: false,
    asset_history_view: false,
    asset_history_edit: false,
    asset_history_delete: false,

    asset_registry_create: false,
    asset_registry_view: false,
    asset_registry_edit: false,
    asset_registry_delete: false,

    asset_insurance_create: false,
    asset_insurance_view: false,
    asset_insurance_edit: false,
    asset_insurance_delete: false,

    asset_bntc_create: false,
    asset_bntc_view: false,
    asset_bntc_edit: false,
    asset_bntc_delete: false,

    report_business_create: false,
    report_business_view: false,
    report_business_edit: false,
    report_business_delete: false,

    report_business_chart_create: false,
    report_business_chart_view: false,
    report_business_chart_edit: false,
    report_business_chart_delete: false,

    report_client_evaluation_create: false,
    report_client_evaluation_view: false,
    report_client_evaluation_edit: false,
    report_client_evaluation_delete: false,

    report_brief_evaluation_create: false,
    report_brief_evaluation_view: false,
    report_brief_evaluation_edit: false,
    report_brief_evaluation_delete: false,

    report_employee_activity_create: false,
    report_employee_activity_view: false,
    report_employee_activity_edit: false,
    report_employee_activity_delete: false,

    user_create: false,
    user_view: false,
    user_edit: false,
    user_delete: false,

    group_user_create: false,
    group_user_view: false,
    group_user_edit: false,
    group_user_delete: false,

    permission_create: false,
    permission_view: false,
    permission_edit: false,
    permission_delete: false,

    config_parameters_create: false,
    config_parameters_view: false,
    config_parameters_edit: false,
    config_parameters_delete: false,

    config_notification_create: false,
    config_notification_view: false,
    config_notification_edit: false,
    config_notification_delete: false,

    config_update_info_create: false,
    config_update_info_view: false,
    config_update_info_edit: false,
    config_update_info_delete: false,
  };

  model = {};

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;

      if (this.item.permission_settings) {
        this.permissionSettings = this.item.permission_settings;
      }
    }
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    const merged = {
      ...this.model,
      permission_settings: this.permissionSettings
    };
    this.apiService.createItem(merged)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    const merged = {
      ...this.model,
      permission_settings: this.permissionSettings
    };
    this.apiService.updateItem(merged)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loopItem) => {
            return loopItem.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdatePermissions(permissionSetting: any): void {

  }
}
