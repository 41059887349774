import {Component, OnInit} from '@angular/core';
import {UsersIndexService} from "../users-index.service";
import {CommonService} from '../../common.service';
import {Router} from "@angular/router";
import {APP_CONFIG} from '../../app.config';

@Component({
  selector: 'app-users-signin',
  templateUrl: 'users-signin.component.html',
  styleUrls: ['users-signin.component.css']
})
export class UsersSigninComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private commonService: CommonService,
              private router: Router) {
  }

  model = {};
  defaultPermission = {
    customer_potential_create: true,
    customer_potential_view: true,
    customer_potential_edit: true,
    customer_potential_delete: true,

    customer_hssv_create: true,
    customer_hssv_view: true,
    customer_hssv_edit: true,
    customer_hssv_delete: true,

    customer_loan_outdate_create: true,
    customer_loan_outdate_view: true,
    customer_loan_outdate_edit: true,
    customer_loan_outdate_delete: true,

    customer_client_create: true,
    customer_client_view: true,
    customer_client_edit: true,
    customer_client_delete: true,

    brief_dxvv_create: true,
    brief_dxvv_view: true,
    brief_dxvv_edit: true,
    brief_dxvv_delete: true,
    brief_dxvv_confirm_payment: true,
    brief_dxvv_schedule: true,
    brief_dxvv_sent: true,
    brief_dxvv_checked: true,

    brief_xhtd_create: true,
    brief_xhtd_view: true,
    brief_xhtd_edit: true,
    brief_xhtd_delete: true,
    brief_xhtd_confirm_payment: true,
    brief_xhtd_schedule: true,
    brief_xhtd_sent: true,
    brief_xhtd_checked: true,

    brief_approvement_create: true,
    brief_approvement_view: true,
    brief_approvement_edit: true,
    brief_approvement_delete: true,
    brief_approvement_confirm_payment: true,
    brief_approvement_schedule: true,
    brief_approvement_sent: true,
    brief_approvement_checked: true,

    brief_hs_giai_ngan_create: true,
    brief_hs_giai_ngan_view: true,
    brief_hs_giai_ngan_edit: true,
    brief_hs_giai_ngan_delete: true,
    brief_hs_giai_ngan_confirm_payment: true,
    brief_hs_giai_ngan_schedule: true,
    brief_hs_giai_ngan_sent: true,
    brief_hs_giai_ngan_checked: true,

    brief_hs_co_no_qua_han_create: true,
    brief_hs_co_no_qua_han_view: true,
    brief_hs_co_no_qua_han_edit: true,
    brief_hs_co_no_qua_han_delete: true,
    brief_hs_co_no_qua_han_confirm_payment: true,
    brief_hs_co_no_qua_han_schedule: true,
    brief_hs_co_no_qua_han_sent: true,
    brief_hs_co_no_qua_han_checked: true,

    brief_hs_co_cau_create: true,
    brief_hs_co_cau_view: true,
    brief_hs_co_cau_edit: true,
    brief_hs_co_cau_delete: true,
    brief_hs_co_cau_confirm_payment: true,
    brief_hs_co_cau_schedule: true,
    brief_hs_co_cau_sent: true,
    brief_hs_co_cau_checked: true,

    brief_finished_create: true,
    brief_finished_view: true,
    brief_finished_edit: true,
    brief_finished_delete: true,
    brief_finished_confirm_payment: true,
    brief_finished_schedule: true,
    brief_finished_sent: true,
    brief_finished_checked: true,

    brief_notify_bank_create: true,
    brief_notify_bank_view: true,
    brief_notify_bank_edit: true,
    brief_notify_bank_delete: true,
    brief_notify_bank_confirm_payment: true,
    brief_notify_bank_schedule: true,
    brief_notify_bank_sent: true,
    brief_notify_bank_checked: true,

    asset_list_create: true,
    asset_list_view: true,
    asset_list_edit: true,
    asset_list_delete: true,

    asset_history_create: true,
    asset_history_view: true,
    asset_history_edit: true,
    asset_history_delete: true,

    asset_registry_create: true,
    asset_registry_view: true,
    asset_registry_edit: true,
    asset_registry_delete: true,

    asset_insurance_create: true,
    asset_insurance_view: true,
    asset_insurance_edit: true,
    asset_insurance_delete: true,

    asset_bntc_create: true,
    asset_bntc_view: true,
    asset_bntc_edit: true,
    asset_bntc_delete: true,

    report_business_create: true,
    report_business_view: true,
    report_business_edit: true,
    report_business_delete: true,

    report_business_chart_create: true,
    report_business_chart_view: true,
    report_business_chart_edit: true,
    report_business_chart_delete: true,

    report_client_evaluation_create: true,
    report_client_evaluation_view: true,
    report_client_evaluation_edit: true,
    report_client_evaluation_delete: true,

    report_brief_evaluation_create: true,
    report_brief_evaluation_view: true,
    report_brief_evaluation_edit: true,
    report_brief_evaluation_delete: true,

    report_employee_activity_create: true,
    report_employee_activity_view: true,
    report_employee_activity_edit: true,
    report_employee_activity_delete: true,

    user_create: true,
    user_view: true,
    user_edit: true,
    user_delete: true,

    group_user_create: true,
    group_user_view: true,
    group_user_edit: true,
    group_user_delete: true,

    permission_create: true,
    permission_view: true,
    permission_edit: true,
    permission_delete: true,

    config_parameters_create: true,
    config_parameters_view: true,
    config_parameters_edit: true,
    config_parameters_delete: true,

    config_notification_create: true,
    config_notification_view: true,
    config_notification_edit: true,
    config_notification_delete: true,

    config_update_info_create: true,
    config_update_info_view: true,
    config_update_info_edit: true,
    config_update_info_delete: true,
  };
  defaultKyHan: any[] = [
    {value: 1, title: '1 tháng'},
    {value: 2, title: '2 tháng'},
    {value: 3, title: '3 tháng'},
    {value: 5, title: '5 tháng'},
    {value: 6, title: '6 tháng'},
    {value: 10, title: '10 tháng'},
    {value: 12, title: '12 tháng'},
    {value: 13, title: '13 tháng'},
    {value: 18, title: '18 tháng'},
    {value: 24, title: '24 tháng'},
    {value: 36, title: '36 tháng'},
    {value: 48, title: '48 tháng'},
  ];

  ngOnInit() {
  }

  signIn(): void {
    setTimeout(() => {
      this.userService.signIn(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status){
          this.commonService.showMessage({
            message: `Đăng nhập vào hệ thống thành công. Chào mừng bạn đến với <strong>${APP_CONFIG.name}</strong>`,
            type: 'success'
          });

          localStorage.setItem('accessToken', resp.content.access_token);

          localStorage.setItem('signedUser_user_id', resp.content.user.id);
          localStorage.setItem('signedUser_username', resp.content.user.username);
          localStorage.setItem('signedUser_last_name', resp.content.user.last_name);
          localStorage.setItem('signedUser_first_name', resp.content.user.first_name);
          localStorage.setItem('signedUser_display_name', resp.content.user.display_name);
          localStorage.setItem('signedUser_email', resp.content.user.email);
          localStorage.setItem('signedUser_role', resp.content.user.role);
          localStorage.setItem('signedUser_lbl_role', resp.content.user.lbl_role);
          localStorage.setItem('signedUser_thumbnail_url', resp.content.user.thumbnail_url);
          localStorage.setItem('signedUser_thumbnail_full_url', resp.content.user.thumbnail_full_url);

          localStorage.setItem('defaultKyHan', JSON.stringify(this.defaultKyHan));
          // If Admin Account -> full access
          if (resp.content.user.id != '1') {
            this.defaultPermission = {
              ...this.defaultPermission,
              ...resp.content.users_group.permission_settings
            };
          }
          localStorage.setItem('signedUser_permission_settings', JSON.stringify(this.defaultPermission));
          localStorage.setItem('signedUser_user_group', JSON.stringify(resp.content.users_group));


          localStorage.setItem('signedUser_address', resp.content.user.address);
          localStorage.setItem('signedUser_city', resp.content.user.city);
          localStorage.setItem('signedUser_country', resp.content.user.country);
          localStorage.setItem('signedUser_postal_code', resp.content.user.postal_code);
          localStorage.setItem('signedUser_remark', resp.content.user.remark);
          localStorage.setItem('signedUser_description', resp.content.user.description);

          localStorage.setItem('signedUser_package_title', resp.content.user.package_title);
          localStorage.setItem('signedUser_lbl_expiry_date', resp.content.user.lbl_expiry_date);
          localStorage.setItem('signedUser_subscription_info', resp.content.user.subscription_info);

          localStorage.setItem('listConsts_asset_type_label', JSON.stringify(resp.content.list_consts.asset_type_label));
          localStorage.setItem('listConsts_purpose', JSON.stringify(resp.content.list_consts.purpose));
          localStorage.setItem('listConsts_currency_type', JSON.stringify(resp.content.list_consts.currency_type));
          localStorage.setItem('listConsts_dv_tinh_kyhan', JSON.stringify(resp.content.list_consts.dv_tinh_kyhan));
          localStorage.setItem('listConsts_nhom_no', JSON.stringify(resp.content.list_consts.nhom_no));
          localStorage.setItem('listConsts_kieu_vay', JSON.stringify(resp.content.list_consts.kieu_vay));
          localStorage.setItem('listConsts_cach_tinh_lai', JSON.stringify(resp.content.list_consts.cach_tinh_lai));
          localStorage.setItem('listConsts_chu_ky_tra_goc', JSON.stringify(resp.content.list_consts.chu_ky_tra_goc));
          localStorage.setItem('listConsts_chu_ky_tra_lai', JSON.stringify(resp.content.list_consts.chu_ky_tra_lai));
          localStorage.setItem('listConsts_business', JSON.stringify(resp.content.list_consts.business));

          localStorage.setItem('listEmployees', JSON.stringify(resp.content.users_list));

          this.router.navigate(['/home/index']);
        }else{
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }

}
