import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class LoansService {

  constructor(private http: HttpClient) { }

  getList(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('customer_id', filterParams.customer_id)
      .set('status', filterParams.status)
      .set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListApplications(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_applications/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListCreditEvaluation(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_credit_evaluation/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListApproval(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_approval/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListRefusal(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_rejected/p/${filterParams.page}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListApproved(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_approved/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListDisbursed(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_disbursed/p/${filterParams.page}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListRisk(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_risk/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListRestructured(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_restructured/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListPaidOff(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('customer_id', filterParams.customer_id).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}loans/list_paid_off/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/detail/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getPayments(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/payments/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getListPayments(id: any, isInterest: any, isReconstructed: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/payments/id/${id}/is_interest/${isInterest}/is_reconstructed/${isReconstructed}/status/0`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getRelatedImages(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/photos/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addRelatedImage(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/add_photo/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeRelatedImage(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/remove_photo/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  uploadRelatedImage(id: number, file: File): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/upload_photo/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData);
  }

  uploadThumbnail(id: number, file: File): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/upload_asset_photo/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData);
  }

  recalculatePrincipal(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/recalculate_principal/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  recalculateInterest(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/recalculate_interest/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  recalculatePayments(id: any, isInterest: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/recalculate_payments/id/${id}/is_interest/${isInterest}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  createPayment(model: any, userId: number): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/add_payment/id/${userId}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  deletePayment(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/remove_payment/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  createItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/create`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update/id/${model.id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateFull(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_full/id/${model.id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateCredits(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_credits/id/${model.id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateRisks(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_risks/id/${model.id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateStatus(id: any, status, reasonParams = {ykien: ''} ): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_status/id/${id}/status/${status}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.patch(url, {'ykien': reasonParams.ykien});
  }

  updateStatusModel(model: any, status ): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_status/id/${model.id}/status/${status}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.patch(url, model);
  }

  getPaidLoan(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_paid/id/${id}/preview/true`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  updatePaidLoan(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_paid/id/${model.id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.patch(url, model);
  }

  updateFeatured(id: any, featured: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/update_featured/id/${id}/featured/${featured}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  deleteItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/delete/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getTerms(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/terms/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getAssets(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/assets/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addAsset(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/add_asset/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeAsset(loanAssetId: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/remove_asset/id/${loanAssetId}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getIncomes(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/incomes/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addIncome(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/add_income/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeIncome(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}loans/remove_income/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getDebts(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/debts/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getCredits(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/credits/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getComments(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/comments/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addComment(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/add_comment/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeComment(id: any, commentId: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}loans/remove_comment/id/${id}/comment_id/${commentId}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getUsers(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/users/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addUser(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/add_user/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeUser(id: any, userID: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/remove_user/id/${id}/user_id/${userID}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getUsersCouncil(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/users/id/${id}/type/dinh_gia`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addUserCouncil(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/add_hd_dinhgia/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeUserCouncil(id: any, userID: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}loans/remove_hd_dinhgia/id/${id}/user_id/${userID}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }


  downloadToTrinh(loanID: any, template: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}downloads/to_trinh/id/${loanID}/template/${template}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    window.open(url, '_blank');
    return ;
  }

  modalDownloadBBDG(loanID: any, template: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}downloads/bien_ban/id/${loanID}/template/${template}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    window.open(url, '_blank');
    return ;
  }

  modalDownloadCoCau(loanID: any, template: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}downloads/co_cau_khoan_vay/id/${loanID}/template/${template}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    window.open(url, '_blank');
    return ;
  }

  downloadHopDong(loanID: any, template: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}downloads/hop_dong_tin_dung/id/${loanID}/template/${template}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    window.open(url, '_blank');
    return ;
  }
}
