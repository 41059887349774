import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class ReportsIndexService {

  constructor(private http: HttpClient) { }

  getReportBusiness(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
                      .set('from_time', filterParams.filter_from_date)
                      .set('to_time', filterParams.filter_to_date);
    const url = `${APP_CONFIG.apiUrl}reports/dashboards/`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getReportCapital(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('from_time', filterParams.filter_from_date)
      .set('to_time', filterParams.filter_to_date);
    const url = `${APP_CONFIG.apiUrl}report/fund/list`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getReportIncome(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('from_time', filterParams.filter_from_date)
      .set('to_time', filterParams.filter_to_date);
    const url = `${APP_CONFIG.apiUrl}report/profit/list`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  downReportIncome(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('from_time', filterParams.filter_from_date)
      .set('to_time', filterParams.filter_to_date);
    const url = `${APP_CONFIG.apiUrl}report/profit/download`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`
      + `&${params}`;
    window.open(url, '_blank');
    return;
  }

  downReportCapital(id): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}report/fund/download/id/${id}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    window.open(url, '_blank');
    return;
  }

  getReportClient(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}reports/client_evaluations/p/${filterParams.page}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getReportBrief(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}reports/brief_evaluations/p/${filterParams.page}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getReportEmployee(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams()
      .set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}reports/employee_activities/p/${filterParams.page}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getList(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}groups/list/p/${filterParams.page}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/detail/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getCapital(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}report/fund/compare/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getItemWithPermissonSettings(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/detail_with_notification_settings/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  createItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/create?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  createCapital(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}report/fund/add?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  createIncome(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}report/profit/change?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/update/id/${model.id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  deleteItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}groups/delete/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

}
