import {Component, Input, OnInit} from '@angular/core';
import {CustomersService} from '../../customers.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-customers-transfer-debts',
  templateUrl: './customers-transfer-debts.component.html',
  styleUrls: ['./customers-transfer-debts.component.css']
})
export class CustomersTranferDebtsComponent implements OnInit {

  constructor(private customerService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) {
  }

  model = {};
  item = {};

  @Input()
  customer: any;

  @Input()
  items: any[] = [];

  ngOnInit() {
    this.model = this.items[0];


  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  // onSubmit(): void {
  //
  //   if (!this.item) {
  //     this.onNew();
  //
  //     return;
  //   }
  //
  //   this.onUpdate();
  // }
  //
  // onNew(): void {
  //   this.apiService.createItem(this.model)
  //     .subscribe(resp => {
  //       if (!resp) {
  //         return;
  //       }
  //
  //       if (resp.status == 1) {
  //         this.items.unshift(resp.content);
  //         this.activeModal.close();
  //
  //         this.commonService.showMessage({
  //           message: 'Cập nhật dữ liệu thành công',
  //           type: 'success'
  //         });
  //       } else {
  //         this.commonService.showMessage({
  //           message: resp.message,
  //           type: 'danger'
  //         });
  //       }
  //     });
  // }
  //
  // onUpdate(): void {
  //   this.apiService.updateItem(this.model)
  //     .subscribe(resp => {
  //       if (!resp) {
  //         return;
  //       }
  //
  //       if (resp.status == 1) {
  //         this.item = resp.content;
  //         this.activeModal.close();
  //
  //         _.remove(this.items, (loop_item) => {
  //           return loop_item.id === this.item.id;
  //         });
  //
  //         this.items.unshift(this.item);
  //
  //         this.commonService.showMessage({
  //           message: 'Cập nhật dữ liệu thành công',
  //           type: 'success'
  //         });
  //       } else {
  //         this.commonService.showMessage({
  //           message: resp.message,
  //           type: 'danger'
  //         });
  //       }
  //
  //
  //     });
  // }
}
