import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {APP_CONFIG} from "../app.config";

@Injectable({
  providedIn: 'root'
})
export class NotificationsIndexService {

  constructor(private http: HttpClient) { }

  getList(filterParams): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('s', filterParams.s);
    let url = `${APP_CONFIG.apiUrl}notifications/list/p/${filterParams.page}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params: params})
  }

  getNew(): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    let url = `${APP_CONFIG.apiUrl}notifications/new?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url)
  }

  getItem(id: any): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    let url = `${APP_CONFIG.apiUrl}notifications/detail/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  deleteItem(id: any): Observable<any> {
    let accessToken = localStorage.getItem('accessToken');
    let url = `${APP_CONFIG.apiUrl}notifications/delete/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }
}
