import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalModule} from 'ngb-modal';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import {HomeIndexComponent} from './home/home-index/home-index.component';
import {InvitationsIndexComponent} from './invitations/invitations-index/invitations-index.component';
import {NotificationsIndexComponent} from './notifications/notifications-index/notifications-index.component';
import {GroupsIndexComponent} from './groups/groups-index/groups-index.component';
import {UsersIndexComponent} from './users/users-index/users-index.component';

import {SettingsIndexComponent} from './settings/settings-index/settings-index.component';
import {SettingsGeneralComponent} from './settings/settings-general/settings-general.component';
import {SettingsAddGeneralComponent} from './settings/popups/settings-add-general/settings-add-general.component';

import { ChartsModule } from 'ng2-charts';
import {PopupComponent} from './_shared/popup/popup.component';
import {UsersSigninComponent} from './users/users-signin/users-signin.component';
import {UsersSignoutComponent} from './users/users-signout/users-signout.component';
import {GroupsAddComponent} from './groups/groups-add/groups-add.component';
import {GroupsViewComponent} from './groups/groups-view/groups-view.component';
import {InvitationsViewComponent} from './invitations/invitations-view/invitations-view.component';
import {NotificationsViewComponent} from './notifications/notifications-view/notifications-view.component';
import {UsersAddComponent} from './users/users-add/users-add.component';
import {UsersPermissionPopupComponent} from './users/users-permission-popup/users-permission-popup.component';
import {UsersViewComponent} from './users/users-view/users-view.component';
import {AppLayoutComponent} from './app-layout/app-layout.component';
import {AccountLayoutComponent} from './account-layout/account-layout.component';
import {UsersSignUpComponent} from './users/users-sign-up/users-sign-up.component';
import {UsersForgotPasswordComponent} from './users/users-forgot-password/users-forgot-password.component';
import {UsersProfileComponent} from './users/users-profile/users-profile.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {CustomersCommentsComponent} from './customers/popups/customers-comments/customers-comments.component';

import {ReportsBusinessComponent} from './reports/reports-business/reports-business.component';
import {ReportsBriefEvaluationComponent} from './reports/reports-brief-evaluation/reports-brief-evaluation.component';
import {ReportsAssetEvaluationComponent} from './reports/reports-asset-evaluation/reports-asset-evaluation.component';
import {ReportsClientEvaluationComponent} from './reports/reports-client-evaluation/reports-client-evaluation.component';
import {ReportsBusinessChartComponent} from './reports/reports-business-chart/reports-business-chart.component';
import {ReportsEmployeeActivityComponent} from './reports/reports-employee-activity/reports-employee-activity.component';
import {ReportBriefViewComponent} from './reports/popups/report-brief-view/report-brief-view.component';

import {AssetsIndexComponent} from './assets/assets-index/assets-index.component';
import {AssetsAddComponent} from './assets/popups/assets-add/assets-add.component';
import {AssetsModifyComponent} from './assets/popups/assets-modify/assets-modify.component';
import {AssetsExportComponent} from './assets/popups/assets-export/assets-export.component';
import {AssetsAddMovableComponent} from './assets/popups/assets-add-movable/assets-add-movable.component';
import {AssetsAddCavetComponent} from './assets/popups/assets-add-cavet/assets-add-cavet.component';
import {AssetsAddRegistrationsComponent} from './assets/popups/assets-add-registrations/assets-add-registrations.component';
import {AssetsAddInsurancesComponent} from './assets/popups/assets-add-insurances/assets-add-insurances.component';
import {AssetsViewComponent} from './assets/popups/assets-view/assets-view.component';
import {AssetsViewMovableComponent} from './assets/popups/assets-view-movable/assets-view-movable.component';
import {AssetsCommentsComponent} from './assets/popups/assets-comments/assets-comments.component';
import {AssetsUsersComponent} from './assets/popups/assets-users/assets-users.component';

import {CustomersPotentialComponent} from './customers/customers-potential/customers-potential.component';
import {CustomersHasLoansComponent} from './customers/customers-has-loans/customers-has-loans.component';
import {CustomersBadCreditComponent} from './customers/customers-bad-credit/customers-bad-credit.component';
import {CustomersMembersComponent} from './customers/customers-members/customers-members.component';
import {LoanApplicationsComponent} from './loans/loan-applications/loan-applications.component';
import {LoanRefusalComponent} from './loans/loan-refusal/loan-refusal.component';
import {CreditEvaluationComponent} from './loans/credit-evaluation/credit-evaluation.component';
import {LoanApprovalComponent} from './loans/loan-approval/loan-approval.component';
import {ApprovedLoansComponent} from './loans/approved-loans/approved-loans.component';
import {RiskLoansComponent} from './loans/risk-loans/risk-loans.component';
import {RestructuredLoansComponent} from './loans/restructured-loans/restructured-loans.component';
import {PaidOffLoansComponent} from './loans/paid-off-loans/paid-off-loans.component';
import {CustomersLoansComponent} from './customers/popups/customers-loans/customers-loans.component';
import {CustomersTranferDebtsComponent} from './customers/popups/customers-transfer-debts/customers-transfer-debts.component';
import {CustomersAddHasLoansComponent} from './customers/popups/customers-add-has-loans/customers-add-has-loans.component';
import {CustomersViewComponent} from './customers/popups/customers-view/customers-view.component';
import {CustomersUsersComponent} from './customers/popups/customers-users/customers-users.component';
import {CustomersAssetsComponent} from './customers/popups/customers-assets/customers-assets.component';
import {CustomersIncomesComponent} from './customers/popups/customers-incomes/customers-incomes.component';
import {CustomersDebtsComponent} from './customers/popups/customers-debts/customers-debts.component';
import {CustomersJobsComponent} from './customers/popups/customers-jobs/customers-jobs.component';
import {CustomersContactsComponent} from './customers/popups/customers-contacts/customers-contacts.component';
import {LoansAddComponent} from './loans/popups/loans-add/loans-add.component';
import {LoansViewComponent} from './loans/popups/loans-view/loans-view.component';
import {LoansUsersComponent} from './loans/popups/loans-users/loans-users.component';
import {LoansTermsComponent} from './loans/popups/loans-terms/loans-terms.component';
import {LoansAssetsComponent} from './loans/popups/loans-assets/loans-assets.component';
import {LoansRisksComponent} from './loans/popups/loans-risks/loans-risks.component';
import {LoansInterestsComponent} from './loans/popups/loans-interests/loans-interests.component';
import {LoansCreditsComponent} from './loans/popups/loans-credits/loans-credits.component';
import {LoansDebtsComponent} from './loans/popups/loans-debts/loans-debts.component';
import {LoansRelatedImagesComponent} from './loans/popups/loans-related-images/loans-related-images.component';
import {AssetsDashboardComponent} from './assets/assets-dashboard/assets-dashboard.component';
import {AssetsMapComponent} from './assets/assets-map/assets-map.component';
import {AssetsRegistrationsComponent} from './assets/assets-registrations/assets-registrations.component';
import {AssetsInsurancesComponent} from './assets/assets-insurances/assets-insurances.component';
import {AssetsCavetComponent} from './assets/assets-cavet/assets-cavet.component';
import {MapLayoutComponent} from './map-layout/map-layout.component';
import {AgmCoreModule} from '@agm/core';
import {AssetsContactsComponent} from './assets/popups/assets-contacts/assets-contacts.component';
import {AssetsYearlyComponent} from './assets/popups/assets-yearly/assets-yearly.component';
import {AssetsDailyComponent} from './assets/popups/assets-daily/assets-daily.component';
import {AssetsWeeklyComponent} from './assets/popups/assets-weekly/assets-weekly.component';
import {AssetsMonthlyComponent} from './assets/popups/assets-monthly/assets-monthly.component';
import {UsersPermissionsComponent} from './users/users-permissions/users-permissions.component';
import {LoansPaymentsComponent} from './loans/popups/loans-payments/loans-payments.component';
import {CustomersAddPotentialComponent} from './customers/popups/customers-add-potential/customers-add-potential.component';
import {LoanRemindersComponent} from './loans/loan-reminders/loan-reminders.component';
import {RemindersAddComponent} from './loans/popups/reminders-add/reminders-add.component';
import {RemindersViewComponent} from './loans/popups/reminders-view/reminders-view.component';
import {LoansCommentsComponent} from './loans/popups/loans-comments/loans-comments.component';
import {RemindersCommentsComponent} from './loans/popups/reminders-comments/reminders-comments.component';
import {LoansIncomesComponent} from './loans/popups/loans-incomes/loans-incomes.component';
import { TabsComponent } from './_shared/tabs/tabs.component';
import { TabComponent } from './_shared/tabs/tab.component';
import {LoansAddApplicationsComponent} from './loans/popups/loans-add-applications/loans-add-applications.component';
import {ClickOutsideDirective} from './_shared/click-outside.directive';
import {AssetsLoansComponent} from './assets/popups/assets-loans/assets-loans.component';
import {DisbursedLoansComponent} from './loans/disbursed-loans/disbursed-loans.component';
import {AssetsDangkiemsComponent} from './assets/popups/assets-dangkiems/assets-dangkiems.component';
import {AssetsBaohiemsComponent} from './assets/popups/assets-baohiems/assets-baohiems.component';
import {AssetsBiennhansComponent} from './assets/popups/assets-biennhans/assets-biennhans.component';
import { LoansSendComponent } from './loans/popups/loans-send/loans-send.component';
import { LoansCouncilComponent } from './loans/popups/loans-council/loans-council.component';
import {ReportsCapitalComponent} from './reports/reports-capital/reports-capital.component';
import {ReportsIncomeComponent} from './reports/reports-income/reports-income.component';
import { ReportIncomeViewComponent } from './reports/popups/report-income-view/report-income-view.component';
import { ReportIncomeAddComponent } from './reports/popups/report-income-add/report-income-add.component';
import { ReportCapitalViewComponent } from './reports/popups/report-capital-view/report-capital-view.component';
import { ReportCapitalAddComponent } from './reports/popups/report-capital-add/report-capital-add.component';
import { LoansSettlementComponent } from './loans/popups/loans-settlement/loans-settlement.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,

    PopupComponent,

    AppLayoutComponent,
    AccountLayoutComponent,

    CustomersPotentialComponent,
    CustomersHasLoansComponent,
    CustomersBadCreditComponent,
    CustomersMembersComponent,
    CustomersAddHasLoansComponent,
    CustomersAddPotentialComponent,
    CustomersViewComponent,
    CustomersUsersComponent,
    CustomersCommentsComponent,
    CustomersAssetsComponent,
    CustomersIncomesComponent,
    CustomersDebtsComponent,
    CustomersLoansComponent,
    CustomersTranferDebtsComponent,
    CustomersJobsComponent,
    CustomersContactsComponent,

    LoanRemindersComponent,
    LoanApplicationsComponent,
    LoanRefusalComponent,
    CreditEvaluationComponent,
    LoanApprovalComponent,
    ApprovedLoansComponent,
    DisbursedLoansComponent,
    RiskLoansComponent,
    RestructuredLoansComponent,
    PaidOffLoansComponent,

    RemindersAddComponent,
    RemindersViewComponent,
    RemindersCommentsComponent,

    LoansAddComponent,
    LoansAddApplicationsComponent,
    LoansViewComponent,
    LoansUsersComponent,
    LoansCommentsComponent,
    LoansTermsComponent,
    LoansAssetsComponent,
    LoansRisksComponent,
    LoansInterestsComponent,
    LoansIncomesComponent,
    LoansCreditsComponent,
    LoansDebtsComponent,
    LoansRelatedImagesComponent,
    LoansPaymentsComponent,

    MapLayoutComponent,

    AssetsIndexComponent,
    AssetsLoansComponent,
    ReportsBusinessComponent,
    ReportsCapitalComponent,
    ReportsIncomeComponent,
    ReportsBriefEvaluationComponent,
    ReportsAssetEvaluationComponent,
    ReportsClientEvaluationComponent,
    ReportsBusinessChartComponent,
    ReportsEmployeeActivityComponent,
    ReportBriefViewComponent,

    AssetsDashboardComponent,
    AssetsMapComponent,
    AssetsRegistrationsComponent,
    AssetsInsurancesComponent,
    AssetsCavetComponent,

    AssetsAddComponent,
    AssetsModifyComponent,
    AssetsExportComponent,
    AssetsAddCavetComponent,
    AssetsAddMovableComponent,
    AssetsAddInsurancesComponent,
    AssetsAddRegistrationsComponent,
    AssetsUsersComponent,
    AssetsViewComponent,
    AssetsViewMovableComponent,
    AssetsContactsComponent,
    AssetsDailyComponent,
    AssetsWeeklyComponent,
    AssetsMonthlyComponent,
    AssetsYearlyComponent,
    AssetsCommentsComponent,
    AssetsDangkiemsComponent,
    AssetsBaohiemsComponent,
    AssetsBiennhansComponent,

    UsersIndexComponent,
    UsersPermissionsComponent,

    UsersAddComponent,
    UsersPermissionPopupComponent,
    UsersViewComponent,
    UsersProfileComponent,

    UsersSigninComponent,
    UsersSignoutComponent,
    UsersSignUpComponent,
    UsersForgotPasswordComponent,

    HomeIndexComponent,
    InvitationsIndexComponent,
    NotificationsIndexComponent,
    GroupsIndexComponent,

    SettingsIndexComponent,
    SettingsGeneralComponent,
    SettingsAddGeneralComponent,

    GroupsAddComponent,
    GroupsViewComponent,
    InvitationsViewComponent,
    NotificationsViewComponent,

    DashboardComponent,

    TabsComponent,
    TabComponent,
    ClickOutsideDirective,
    LoansSendComponent,
    LoansCouncilComponent,
    ReportIncomeViewComponent,
    ReportIncomeAddComponent,
    ReportCapitalViewComponent,
    ReportCapitalAddComponent,
    LoansSettlementComponent
  ],
  entryComponents: [
    PopupComponent,

    CustomersAddHasLoansComponent,
    CustomersAddPotentialComponent,
    CustomersViewComponent,
    CustomersAssetsComponent,
    CustomersIncomesComponent,
    CustomersDebtsComponent,
    CustomersLoansComponent,
    CustomersTranferDebtsComponent,
    CustomersUsersComponent,
    CustomersCommentsComponent,
    CustomersJobsComponent,
    CustomersContactsComponent,

    RemindersAddComponent,
    RemindersViewComponent,
    RemindersCommentsComponent,

    LoansAddComponent,
    LoansAddApplicationsComponent,
    LoansViewComponent,
    LoansUsersComponent,
    LoansCommentsComponent,
    LoansTermsComponent,
    LoansAssetsComponent,
    LoansRisksComponent,
    LoansInterestsComponent,
    LoansIncomesComponent,
    LoansCreditsComponent,
    LoansDebtsComponent,
    LoansRelatedImagesComponent,
    LoansPaymentsComponent,

    AssetsAddComponent,
    AssetsLoansComponent,
    AssetsModifyComponent,
    AssetsExportComponent,
    AssetsAddMovableComponent,
    AssetsAddCavetComponent,
    AssetsAddInsurancesComponent,
    AssetsAddRegistrationsComponent,
    AssetsUsersComponent,
    AssetsViewComponent,
    AssetsViewMovableComponent,
    AssetsContactsComponent,
    AssetsDailyComponent,
    AssetsWeeklyComponent,
    AssetsMonthlyComponent,
    AssetsYearlyComponent,
    AssetsCommentsComponent,
    AssetsDangkiemsComponent,
    AssetsBaohiemsComponent,
    AssetsBiennhansComponent,

    UsersAddComponent,
    UsersPermissionPopupComponent,
    UsersViewComponent,

    GroupsAddComponent,
    GroupsViewComponent,
    InvitationsViewComponent,
    NotificationsViewComponent,
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBihjvllCcHzbrJ-Hf-RGs2VX19iXixm38'
    }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    ModalModule,
    AppRoutingModule,
    ChartsModule,
    NgxMaskModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
