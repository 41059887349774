import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from "../../users/users-index.service";

@Component({
  selector: 'app-home-index',
  templateUrl: 'home-index.component.html',
  styleUrls: ['home-index.component.css']
})
export class HomeIndexComponent implements OnInit {

  constructor(private userService: UsersIndexService) { }

  ngOnInit() {
    this.userService.validateAccessToken();
  }

}
