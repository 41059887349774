import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../common.service';

declare let $: any;
declare let md: any;
@Component({
  selector: 'app-report-loans-view',
  templateUrl: './report-brief-view.component.html',
  styleUrls: ['./report-brief-view.component.css']
})
export class ReportBriefViewComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    public activeModal: NgbActiveModal) { }

  model = {
    id: '',
    lbl_id: '',
    amt: 0,
    title_payment: '-',
    tong_goc_da_thu: 0,
    tong_lai_da_thu: 0,
    du_no_goc: 0,
    lai_du_thu: 0,
    tong_khoan_thanh_toan: 0,
    count_tong_khoan_thanh_toan: 0,
    tong_khoan_tra_dung_han: 0,
    count_tong_khoan_tra_dung_han: 0,
    tong_thanh_toan_tre: 0,
    count_tong_thanh_toan_tre: 0,
    so_ngay_tre_han: 0,
    tong_nguy_co_mat_von: 0,
    lai_du_kien: 0
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;


  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }

    this.initMaterialWizard();
  }
  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

  initMaterialWizard() {

    setTimeout(function() {
      $('.card.card-wizard').addClass('active');
    }, 600);
  }
}
