import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from "../users-index.service";
import {CommonService} from '../../common.service';

declare let $: any;

@Component({
  selector: 'app-users-forgot-password',
  templateUrl: './users-forgot-password.component.html',
  styleUrls: ['./users-forgot-password.component.css']
})
export class UsersForgotPasswordComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private commonService: CommonService) { }

  model = {};

  ngOnInit() {
    $(".forgot").hide();
    $(".forgot-01").show();

    this.model['user_id'] = 0;
  }

  onForgot(): void {
    setTimeout(() => {
      this.userService.getResetCode(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status){
          this.model['user_id'] = resp.content.user_id;
          this.model['email'] = resp.content.email;
          $(".forgot").hide();
          $(".forgot-02").show();
        }else{
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }

  onNext(): void {
    setTimeout(() => {
      this.userService.validateResetCode(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status){
          this.model['user_id'] = resp.content.user_id;
          this.model['reset_code'] = resp.content.reset_code;
          $(".forgot").hide();
          $(".forgot-03").show();
        }else{
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }

  onCompleteReset(): void {
    setTimeout(() => {
      this.userService.completeResetPassword(this.model).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status){
          this.model['user_id'] = resp.content.user_id;
          $(".forgot").hide();
          $(".forgot-04").show();
        }else{
          this.commonService.showMessage({
            message: resp.message.split("\n").join("<br />"),
            type: 'danger'
          });
        }

      })
    }, 50);
  }
}
