import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {APP_CONFIG} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class UsersIndexService {

  constructor(private http: HttpClient,
              private router: Router) { }

  getList(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}users/list/p/${filterParams.page}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/detail/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getCustomerItem(customerId: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/customer_detail/id/${customerId}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getItemWithNotificationSettings(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/detail_with_notification_settings/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  createItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/create?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/update/id/${model.id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateProfile(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/update_profile/id/${model.id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updatePassword(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/update_password/id/${model.id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateThumbnail(id: any, file: File): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/update_thumbnail/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;

    const formData: FormData = new FormData();
    formData.append('userThumbnail', file, file.name);
    return this.http.post(url, formData);
  }

  updateNotificationSettings(id: any, notificationSetting: any, settingValue: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/update_notification_settings/id/${id}/notification_setting/`
              + `${notificationSetting}/setting_value/${settingValue}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  lockItem(id: any, status): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/lock/id/${id}/status/${status}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  deleteItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}users/delete/id/${id}?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  validateAccessToken() {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken !== undefined && accessToken !== '' && accessToken !== '0') {
      const url = `${APP_CONFIG.apiUrl}users/validate_access_token?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
      setTimeout(() => {
        this.http.get(url).subscribe(resp => {
          // @ts-ignore
          if (!resp || !resp.status) {
            this.router.navigate(['/users/signin']);
          }
        });
      }, 50);
    } else {
      this.router.navigate(['/users/signin']);
    }
  }

  signIn(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/sign_in?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  signUp(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/sign_up?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  resendSignUpCode(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/resend_signup_code?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  validateEmail(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/validate_email?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  completeSignUp(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/complete_sign_up?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  getResetCode(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/get_reset_code?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  resendResetCode(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/resend_reset_code?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  validateResetCode(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/validate_reset_code?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }

  completeResetPassword(model: any): Observable<any> {
    const url = `${APP_CONFIG.apiUrl}users/complete_reset_password?api_token=${APP_CONFIG.apiToken}`;
    return this.http.post(url, model);
  }
}
