import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AssetsIndexService} from "../../assets-index.service";
import {AssetsViewComponent} from "../assets-view/assets-view.component";
import {AssetsContactsComponent} from "../assets-contacts/assets-contacts.component";
import {AssetsDailyComponent} from "../assets-daily/assets-daily.component";
import {AssetsWeeklyComponent} from "../assets-weekly/assets-weekly.component";
import {AssetsYearlyComponent} from "../assets-yearly/assets-yearly.component";

@Component({
  selector: 'app-assets-monthly',
  templateUrl: './assets-monthly.component.html',
  styleUrls: ['./assets-monthly.component.css']
})
export class AssetsMonthlyComponent implements OnInit {

  constructor(private assetService: AssetsIndexService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  modalView(itemId): void {
    this.activeModal.close('Close click')
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    })
  }

  modalContacts(itemId): void {
    this.activeModal.close('Close click')
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsContactsComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    })
  }

  modalDaily(itemId): void {
    this.activeModal.close('Close click')
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsDailyComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    })
  }

  modalWeekly(itemId): void {
    this.activeModal.close('Close click')
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsWeeklyComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    })
  }

  modalMonthly(itemId): void {
    this.activeModal.close('Close click')
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsMonthlyComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    })
  }

  modalYearly(itemId): void {
    this.activeModal.close('Close click')
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsYearlyComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    })
  }
}
