import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../common.service';
import * as _ from 'lodash';

import {CustomersService} from '../../customers.service';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-customers-add-potential',
  templateUrl: 'customers-add-potential.component.html',
  styleUrls: ['customers-add-potential.component.css'],
  providers: [CustomersService, CommonService]
})
export class CustomersAddPotentialComponent implements OnInit {

  constructor(private apiService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    lbl_birthdate: '',
    birthdate_year: '',
    customer_type: '',
    source: '',
    gender: '',
    credit_rating: '',
    marriage_status: '',
    education: '',
    is_primary_current: ''
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;

      this.model = {
        ...this.model,
        is_primary_current: this.item.is_primary_current.toString()
      };
    }
    this.initMaterialWizard();

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_customer_type').selectpicker('refresh');
      $('.selectpicker_customer_type').selectpicker('val', this.model.customer_type);

      $('.selectpicker_source').selectpicker('refresh');
      $('.selectpicker_source').selectpicker('val', this.model.source);

      $('.selectpicker_gender').selectpicker('refresh');
      $('.selectpicker_gender').selectpicker('val', this.model.gender);

      $('.selectpicker_credit_rating').selectpicker('refresh');
      $('.selectpicker_credit_rating').selectpicker('val', this.model.credit_rating);

      $('.selectpicker_marriage_status').selectpicker('refresh');
      $('.selectpicker_marriage_status').selectpicker('val', this.model.marriage_status);

      $('.selectpicker_education').selectpicker('refresh');
      $('.selectpicker_education').selectpicker('val', this.model.education);
    }, 50);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();

      if (key == 'lbl_birthdate') {
        const date = $($event.target).val();
        if (date.slice(0, 5) === '01/01') {
          this.model.birthdate_year = date.slice(6, 11);
          console.log(this.model);
        }
      }
    }, 400);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateItemPotential(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status == 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loop_item) => {
            return loop_item.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  initMaterialWizard() {
    $('.card-wizard').bootstrapWizard({
      tabClass: 'nav nav-pills',
      nextSelector: '.btn-next',
      previousSelector: '.btn-previous',

      onPrevious(tab, navigation, index) {
      },

      onNext(tab, navigation, index) {
      },

      onInit(tab, navigation, index) {

      },

      onTabClick(tab, navigation, index) {
      },

      onTabShow(tab, navigation, index) {

      }
    });

    setTimeout(function() {
      $('.card.card-wizard').addClass('active');
    }, 600);

    function refreshAnimation($wizard, index) {
      let $total = $wizard.find('.nav li').length;
      let $li_width = 100 / $total;

      let total_steps = $wizard.find('.nav li').length;
      let move_distance = $wizard.width() / total_steps;
      let index_temp = index;
      let vertical_level = 0;

      let mobile_device = $(document).width() < 600 && $total > 3;

      if (mobile_device) {
        move_distance = $wizard.width() / 2;
        index_temp = index % 2;
        $li_width = 50;
      }

      $wizard.find('.nav li').css('width', $li_width + '%');

      let step_width = move_distance;
      move_distance = move_distance * index_temp;

      let $current = index + 1;

      if ($current == 1 || (mobile_device == true && (index % 2 == 0))) {
        move_distance -= 8;
      } else if ($current == total_steps || (mobile_device == true && (index % 2 == 1))) {
        move_distance += 8;
      }

      if (mobile_device) {
        vertical_level = index / 2;
        vertical_level = vertical_level * 38;
      }

      $wizard.find('.moving-tab').css('width', step_width);
      $('.moving-tab').css({
        transform: 'translate3d(' + move_distance + 'px, ' + vertical_level + 'px, 0)',
        transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'

      });
    }
  }

}
