import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {CommonService} from '../../common.service';
import * as moment from 'moment';
import {ReportsIndexService} from '../reports-index.service';
import {ReportIncomeAddComponent} from '../popups/report-income-add/report-income-add.component';
import {ReportIncomeViewComponent} from '../popups/report-income-view/report-income-view.component';

declare let $: any;
declare let md: any;
@Component({
  selector: 'app-reports-income',
  templateUrl: 'reports-income.component.html',
  styleUrls: ['reports-income.component.css']
})
export class ReportsIncomeComponent implements OnInit {

  constructor(private reportService: ReportsIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
    filter_from_date: '',
    filter_to_date: '',
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  loading = true;
  items = [];

  ngOnInit() {
    this.onChangeTimePeriod();
    this.loadList();

    setTimeout(() => {
      $('.filter_time_period_selectpicker').selectpicker();
      $('.filter_select_quarter').selectpicker('refresh');
    }, 50);
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = $($event.target).val();
    }, 50);

    this.onChangeTimePeriod();
  }

  onChangeTimePeriod(): void {
    setTimeout(() => {
      $('.filter_select_quarter').selectpicker('refresh');
    }, 50);

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
  }

  loadList(): void {
    this.loading = false;
    setTimeout(() => {
      this.reportService.getReportIncome(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
      });
    }, 50);
  }

  onFilterSubmit(): void {
    this.loading = true;
    this.loadList();
  }

  onDownload(date): void {

    if (date) {
      this.filterParams.filter_from_date = date;
      this.filterParams.filter_to_date = date;
    } else {
      this.filterParams.filter_from_date = this.items[this.items.length-1].lbl_date;
      this.filterParams.filter_to_date = this.items[0].lbl_date;
    }

    this.reportService.downReportIncome(this.filterParams);
  }

  modalAdd(): void {
    const modalRef = this.modalService.open(ReportIncomeAddComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.modal_action = 'add';
    modalRef.componentInstance.items = this.items;
    modalRef.componentInstance.modal_title = 'Thêm báo cáo thu nhập';
  }

  modalUpdate(item): void {
    const modalRef = this.modalService.open(ReportIncomeAddComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.item = item;
    modalRef.componentInstance.items = this.items;
    modalRef.componentInstance.modal_title = item.lbl_date;
  }

  modalView(item): void {
    const modalRef = this.modalService.open(ReportIncomeViewComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.item = item;
    modalRef.componentInstance.modal_title = item.lbl_date;
  }
}
