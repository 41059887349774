import {Component, Input, OnInit} from '@angular/core';
import {CustomersService} from '../../customers.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
  selector: 'app-customers-assets',
  templateUrl: './customers-assets.component.html',
  styleUrls: ['./customers-assets.component.css']
})
export class CustomersAssetsComponent implements OnInit {

  constructor(private customerService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    asset_type: 0,
    attachments: [],
    image_url: '',
    image_full_url: '',
    contact_ids: {},
  };

  @Input()
  customer: any = {
    user_id: 0,
    title: ''
  };

  @Input()
  items: any[] = [];

  @Input()
  attachments: any[] = [];

  customerContacts: any[];
  defaultKyHan: any[];
  contactNumber: any[] = [0];

  ngOnInit() {

    this.getCustomerContacts(this.customer.id);
    this.defaultKyHan = JSON.parse(localStorage.getItem('defaultKyHan'));

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });

    setTimeout(() => {
      $('.selectpicker_tai_san_status').selectpicker('refresh');
      $('.selectpicker_asset_type').selectpicker('refresh');
      $('.selectpicker_loai_tien').selectpicker('refresh');
      $('.selectpicker_stk_ky_han').selectpicker('refresh');
      $('.selectpicker_contact_id').selectpicker('refresh');
    }, 50);
  }

  onAddContactUser(): void {
    this.contactNumber.push(this.contactNumber.length);
    setTimeout(() => {
      $('.selectpicker_contact_id').selectpicker('refresh');
    }, 50);
  }

  onRemoveContactUser(itemIndex): void {
    this.contactNumber.splice(itemIndex, 1);
    delete this.model.contact_ids['contact_id_' + itemIndex];

    setTimeout(() => {
      $('.selectpicker_contact_id').selectpicker('refresh');
    }, 50);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
      if (key === 'asset_type') {
        this.model[key] = parseInt($($event.target).val(), 0);
      }
      if (key.slice(0, 10) === 'contact_id') {
        this.model.contact_ids[key] = parseInt($($event.target).val(), 0);
      }
      if (['idCardFSImg', 'idCardBSImg', 'cavetFSImg', 'cavetBSImg'].includes(key)) {
        this.model[key] = $event.target.files.item(0);
      }
    }, 400);
  }

  onSubmit(): void {
    this.customerService.addAsset(this.customer.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.customer = resp.content.customer;
        this.items = resp.content.items;
        // this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }

    });
  }

  onRemove(id): void {
    this.customerService.removeAsset(id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.customer = resp.content.customer;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }


    });
  }

  onUploadThumbnail(itemId, files: FileList): void {
    setTimeout(() => {
      this.customerService.uploadThumbnail(parseInt(itemId, 0), files.item(0))
        .subscribe(resp => {
          if (!resp) {
            return;
          }
          if (resp.status) {
            this.attachments.push(resp.content);
            this.model.attachments = this.attachments;
            this.model.image_url = resp.content.image_url;
            this.model.image_full_url = resp.content.image_full_url;
            this.commonService.showMessage({
              message: 'Thêm thành công',
              type: 'success'
            });
          } else {
            this.commonService.showMessage({
              message: resp.message.split('\n').join('<br />'),
              type: 'danger'
            });
          }
        });
    }, 50);
  }

  getCustomerContacts(customerId): void {
    this.customerService.getContacts(customerId)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.customerContacts = resp.content.items;
        }
        setTimeout(() => {
          $('.selectpicker_contact_id').selectpicker('refresh');
        }, 50);

      });
  }
}
