import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from '../../users/users-index.service';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {PopupComponent} from '../../_shared/popup/popup.component';
import {CommonService} from '../../common.service';
import {LoansService} from '../loans.service';
import {CustomersService} from '../../customers/customers.service';
import {LoansTermsComponent} from '../popups/loans-terms/loans-terms.component';
import {LoansAssetsComponent} from '../popups/loans-assets/loans-assets.component';
import {LoansDebtsComponent} from '../popups/loans-debts/loans-debts.component';
import {LoansCreditsComponent} from '../popups/loans-credits/loans-credits.component';
import {LoansUsersComponent} from '../popups/loans-users/loans-users.component';
import {LoansViewComponent} from '../popups/loans-view/loans-view.component';
import {LoansAddComponent} from '../popups/loans-add/loans-add.component';
import {
  LoansRelatedImagesComponent,
} from '../popups/loans-related-images/loans-related-images.component';
import {LoansCommentsComponent} from '../../loans/popups/loans-comments/loans-comments.component';
import {LoansIncomesComponent} from '../popups/loans-incomes/loans-incomes.component';
import {LoansAddApplicationsComponent} from '../popups/loans-add-applications/loans-add-applications.component';
import {CustomersViewComponent} from '../../customers/popups/customers-view/customers-view.component';
import {CustomersAssetsComponent} from '../../customers/popups/customers-assets/customers-assets.component';

declare let $: any;

@Component({
  selector: 'app-loan-applications',
  templateUrl: './loan-applications.component.html',
  styleUrls: ['./loan-applications.component.css']
})
export class LoanApplicationsComponent implements OnInit {

  constructor(private loanService: LoansService,
              private customerService: CustomersService,
              private userService: UsersIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    customer_id: 0,
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: any[] = [];

  newStatus = 0;

  customers: any[] = [];
  loading = true;

permissionSettings: any;

  ngOnInit() {
    this.userService.validateAccessToken();

    this.loadList();
    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));

    this.customerService.getList({
      s: '',
      page: 0,
    }).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.customers = resp.content.items;

      $(document).ready(() => {
        $('.filter_selectpicker').selectpicker();
        $('.filter_condition_selectpicker').selectpicker();
      });
    });
  }

  filterList(): void {
    console.log(this.filterParams);
    this.filterParams.page = 1;
    this.loadList();
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = parseInt($($event.target).val());
      this.loadList();
    }, 400);
  }



  loadList(): void {
    setTimeout(() => {
      this.loanService.getListApplications(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
        this.total = resp.content.total;
        this.ppp = resp.content.ppp;
      });
    }, 50);
  }

  modalTerms(itemId): void {
    this.loanService.getTerms(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansTermsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.workPackage = resp.content.work_package;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalAssets(itemId, customerID): void {
    this.loanService.getAssets(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansAssetsComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.loanItems = resp.content.items;
      modalRef.componentInstance.loan = resp.content.loan;

      this.customerService.getAssets(customerID).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        modalRef.componentInstance.customer = resp.content.customer;
        modalRef.componentInstance.customerItems = resp.content.items;
      });

    });
  }

  modalImages(itemId): void {
    this.loanService.getRelatedImages(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansRelatedImagesComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.loan = resp.content.loan;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalIncomes(itemId): void {
    this.loanService.getIncomes(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansIncomesComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.loan = resp.content.loan;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalDebts(itemId): void {
    this.loanService.getDebts(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansDebtsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.workPackage = resp.content.work_package;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalCredits(itemId): void {
    this.loanService.getCredits(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansCreditsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.workPackage = resp.content.work_package;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalUsers(itemId): void {
    this.loanService.getUsers(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansUsersComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.workPackage = resp.content.work_package;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalComments(itemId): void {
    this.loanService.getComments(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansCommentsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.loan = resp.content.loan;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalView(itemId): void {
    this.loanService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_tasks = resp.content.count_tasks;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_invitations = resp.content.count_invitations;
    });
  }

  modalViewCustomer(itemId): void {
    this.customerService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(CustomersViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
    });
  }

  modalAdd() {
    const modalRef = this.modalService.open(LoansAddApplicationsComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.modal_action = 'add';
    modalRef.componentInstance.modal_title = 'Tạo hồ sơ vay mới';
    modalRef.componentInstance.items = this.items;
  }

  modalUpdate(itemId) {
    this.loanService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(LoansAddApplicationsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.modal_action = 'update';
      modalRef.componentInstance.modal_title = `<strong>${resp.content.item.title}</strong>`;
      modalRef.componentInstance.items = this.items;
      modalRef.componentInstance.item = resp.content.item;
    });
  }

  modalSend(itemId) {
    this.loanService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Gửi hồ sơ đi xếp hạng tín dụng';
      if (resp.content.item.loai_taisan_dambao == 3) {
        // Chứng chỉ tiền gửi, chuyển status qua thẳng Hồ sơ đã giải ngân
        this.newStatus = 3;
        modalRef.componentInstance.description = `Bạn có muốn duyệt hồ sơ <strong>${resp.content.item.lbl_id}</strong> để chờ giải ngân không?`;
      } else {
        this.newStatus = 1;
        modalRef.componentInstance.description = `Bạn có muốn gửi hồ sơ <strong>${resp.content.item.lbl_id}</strong> đi xếp hạng tín dụng không?`;
      }

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.loanService.updateStatus(itemId, this.newStatus).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          if (resp.status == 1) {
            _.remove(this.items, (loop_item) => {
              return loop_item.id === resp.content.id;
            });

            this.commonService.showMessage({
              message: 'Cập nhật dữ liệu thành công',
              type: 'success'
            });
          } else {
            this.commonService.showMessage({
              message: resp.message,
              type: 'danger'
            });
          }
        });
      });
    });

  }

  modalFeatured(itemId, isFeatured) {
    this.loanService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Thay đổi Top';
      modalRef.componentInstance.description = `Bạn có muốn thay đổi Top cho hồ sơ vay <strong>${resp.content.item.title}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.loanService.updateFeatured(itemId, isFeatured).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          this.loadList();

          this.commonService.showMessage({
            message: 'Cập nhật thành công',
            type: 'success',
          });
        });
      });
    });

  }

  modalDelete(itemId) {
    this.loanService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Xóa dữ liệu';
      modalRef.componentInstance.description = `Bạn có muốn xóa hồ sơ vay <strong>${resp.content.item.title}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.loanService.deleteItem(itemId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          _.remove(this.items, (loop_item) => {
            return loop_item.id === itemId;
          });

          this.commonService.showMessage({
            message: 'Dữ liệu đã được xóa khỏi hệ thống',
            type: 'success',
          });
        });
      });
    });

  }

}
