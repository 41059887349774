import {Component, Input, OnInit} from '@angular/core';
import {AssetsIndexService} from "../../assets-index.service";
import {CommonService} from "../../../common.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-assets-loans',
  templateUrl: './assets-loans.component.html',
  styleUrls: ['./assets-loans.component.css']
})
export class AssetsLoansComponent implements OnInit {

  constructor(private assetService: AssetsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) {
  }

  model = {};

  @Input()
  asset: any;

  @Input()
  items: any[] = [];

  ngOnInit() {

  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);
  }

}
