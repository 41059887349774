import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../../common.service';
import {LoansService} from '../../loans.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-loans-council',
  templateUrl: './loans-council.component.html',
  styleUrls: ['./loans-council.component.css']
})
export class LoansCouncilComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              private commonService: CommonService,
              private loanService: LoansService) { }

  model = {};

  @Input()
  loan: any;

  @Input()
  items: any[] = [];

  ngOnInit() {
  }

  onSubmit(): void {
    this.loanService.addUserCouncil(this.loan.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {
        this.loan = resp.content.loan;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Cập nhật thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }

    });
  }

  onRemove(user_id): void {
    this.loanService.removeUserCouncil(this.loan.id, user_id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status) {

        _.remove(this.items, (loop_item) => {
          return loop_item.user_id === user_id;
        });

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      } else {
        this.commonService.showMessage({
          message: resp.message.split('\n').join('<br />'),
          type: 'danger'
        });
      }

    });
  }
}
