import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-users-signout',
  templateUrl: './users-signout.component.html',
  styleUrls: ['./users-signout.component.css']
})
export class UsersSignoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

    localStorage.removeItem('accessToken');
    localStorage.removeItem('user_username');
    localStorage.removeItem('user_first_name');
    localStorage.removeItem('user_last_name');
    localStorage.removeItem('user_display_name');
    localStorage.removeItem('user_email');
    localStorage.removeItem('user_role');
    localStorage.removeItem('user_lbl_role');
    localStorage.removeItem('user_thumbnail_url');
    localStorage.removeItem('user_thumbnail_full_url');


    this.router.navigate(['/users/signin']);
  }

}
