import {Component, OnInit, Input} from '@angular/core';
import {NotificationsIndexService} from '../notifications/notifications-index.service';
import {InvitationsIndexService} from '../invitations/invitations-index.service';
import {UsersIndexService} from '../users/users-index.service';
import { registerLocaleData } from '@angular/common';
import fr from '@angular/common/locales/fr';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private notificationService: NotificationsIndexService,
              private invitationService: InvitationsIndexService) {
  }

  signedUser_user_id = '';
  signedUser_display_name = '';
  signedUser_thumbnail_url = '';

  new_invitations = {};
  new_notifications = {};

  permissionSettings: any;

  ngOnInit() {
    registerLocaleData( fr );

    this.userService.validateAccessToken();

    this.signedUser_user_id = localStorage.getItem('signedUser_user_id');
    this.signedUser_display_name = localStorage.getItem('signedUser_display_name');
    this.signedUser_thumbnail_url = localStorage.getItem('signedUser_thumbnail_url');

    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));

    setTimeout(() => {
      this.invitationService.getNew().subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }

        this.new_invitations = resp.content.items;
      });
    }, 50);

    setTimeout(() => {
      this.notificationService.getNew().subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }

        this.new_notifications = resp.content.items;
      });
    }, 50);

  }

}
