import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from '../../users/users-index.service';
import {AssetsIndexService} from '../assets-index.service';
import {LoansService} from '../../loans/loans.service';
import {CustomersService} from '../../customers/customers.service';
import {CommonService} from '../../common.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {APP_CONFIG} from '../../app.config';
import {AssetsUsersComponent} from '../popups/assets-users/assets-users.component';
import {AssetsCommentsComponent} from '../popups/assets-comments/assets-comments.component';
import {AssetsViewComponent} from '../popups/assets-view/assets-view.component';

import * as _ from 'lodash';
import {AssetsAddMovableComponent} from '../popups/assets-add-movable/assets-add-movable.component';
import {AssetsViewMovableComponent} from '../popups/assets-view-movable/assets-view-movable.component';

declare let $: any;
declare let md: any;

@Component({
  selector: 'app-assets-dashboard',
  templateUrl: './assets-dashboard.component.html',
  styleUrls: ['./assets-dashboard.component.css']
})
export class AssetsDashboardComponent implements OnInit {

  constructor(private assetService: AssetsIndexService,
              private loanService: LoansService,
              private customerService: CustomersService,
              private userService: UsersIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) {
  }

  filterParams = {
    status: 0,
    s: '',
    customer_id: 0,
    loan_id: 0,
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: any[] = [];

  customers: any[] = [];
  loans: any[] = [];
  loading = true;

permissionSettings: any;

  ngOnInit() {
    this.userService.validateAccessToken();

    this.loadList();
    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));
  }

  filterList(): void {
    this.filterParams.page = 1;
    this.loadList();
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = parseInt($($event.target).val());
      this.loadList();
    }, 400);
  }



  loadList(): void {
    setTimeout(() => {
      this.assetService.getList(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
        this.total = resp.content.total;
        this.ppp = resp.content.ppp;
      });
    }, 50);
  }

  modalUsers(itemId): void {
    this.assetService.getUsers(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsUsersComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.asset = resp.content.asset;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalComments(itemId): void {
    this.assetService.getComments(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsCommentsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.asset = resp.content.asset;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalView(itemId): void {
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    });
  }

  modalViewMovable(itemId): void {
    this.assetService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(AssetsViewMovableComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
      modalRef.componentInstance.count_photos = resp.content.count_photos;
      modalRef.componentInstance.count_attachments = resp.content.count_attachments;
      modalRef.componentInstance.count_users = resp.content.count_users;
      modalRef.componentInstance.count_comments = resp.content.count_comments;
    });
  }

  modalAssetMovableHistory(itemId) {
    this.assetService.getMovableHistory(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(AssetsAddMovableComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.items = resp.content.items;
      modalRef.componentInstance.asset = resp.content.asset;
    });
  }

}
