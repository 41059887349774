import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {CustomersService} from '../../../customers/customers.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LoansService} from '../../loans.service';

declare let $: any;

@Component({
  selector: 'app-loans-add-applications',
  templateUrl: './loans-add-applications.component.html',
  styleUrls: ['./loans-add-applications.component.css']
})
export class LoansAddApplicationsComponent implements OnInit {

  constructor(private customersService: CustomersService,
              private apiService: LoansService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    customer_s: '',
    customer_id: '',
    contact_dong_trachnhiem_1: '',
    contact_dong_trachnhiem_2: '',
    contact_dong_trachnhiem_3: '',
    contact_dong_trachnhiem_4: '',
    contact_dong_trachnhiem_5: '',
    dv_tinh_kyhan: '',
    loaihinh_thechap: '',
    loai_taisan_dambao: '',
    lbl_id: '',
    loan_purpose: '',
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;
  listConsts = {
    currency_type: '',
    cach_tinh_lai: '',
    nhom_no: '',
    asset_type_label: '',
    chu_ky_tra_goc: '',
    chu_ky_tra_lai: '',
    dv_tinh_kyhan: '',
    kieu_vay: '',
    purpose: '',
    business: '',
    listEmployees: {id: '', group_name: '', display_name: ''},
  };

  customers: any[];
  customerContacts: any[];

  ngOnInit() {

    this.initMaterialWizard();

    if (this.item) {
      this.model = this.item;
      this.getListCustomers(this.item.customer_title);
      this.getCustomerContacts(this.item.customer_id);
    } else {
      this.getListCustomers('');
      this.getCustomerContacts(0);
    }

    this.listConsts = {
      currency_type : JSON.parse(localStorage.getItem('listConsts_currency_type')),
      cach_tinh_lai : JSON.parse(localStorage.getItem('listConsts_cach_tinh_lai')),
      nhom_no : JSON.parse(localStorage.getItem('listConsts_nhom_no')),
      asset_type_label : JSON.parse(localStorage.getItem('listConsts_asset_type_label')),
      chu_ky_tra_goc : JSON.parse(localStorage.getItem('listConsts_chu_ky_tra_goc')),
      chu_ky_tra_lai : JSON.parse(localStorage.getItem('listConsts_chu_ky_tra_lai')),
      dv_tinh_kyhan : JSON.parse(localStorage.getItem('listConsts_dv_tinh_kyhan')),
      kieu_vay : JSON.parse(localStorage.getItem('listConsts_kieu_vay')),
      purpose : JSON.parse(localStorage.getItem('listConsts_purpose')),
      business : JSON.parse(localStorage.getItem('listConsts_business')),
      listEmployees: JSON.parse(localStorage.getItem('listEmployees')),
    };

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
    setTimeout(() => {
      $('.selectpicker_loai_taisan_dambao').selectpicker('refresh');
      $('.selectpicker_loai_taisan_dambao').selectpicker('val', this.model.loai_taisan_dambao);

      $('.selectpicker_dv_tinh_kyhan').selectpicker('refresh');
      $('.selectpicker_dv_tinh_kyhan').selectpicker('val', this.model.dv_tinh_kyhan);

      $('.selectpicker_loaihinh_thechap').selectpicker('refresh');
      $('.selectpicker_loaihinh_thechap').selectpicker('val', this.model.loaihinh_thechap);

      $('.selectpicker_muc_dich_vay').selectpicker('refresh');
      $('.selectpicker_muc_dich_vay').selectpicker('val', this.model.loan_purpose);

    }, 50);

    setTimeout(() => {
      $('.selectpicker_customer_id').selectpicker('refresh');
      $('.selectpicker_customer_id').selectpicker('val', this.model.customer_id);
      $('.selectpicker_contact_dong_trachnhiem_1').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_1').selectpicker('val', this.model.contact_dong_trachnhiem_1);

      $('.selectpicker_contact_dong_trachnhiem_2').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_2').selectpicker('val', this.model.contact_dong_trachnhiem_2);

      $('.selectpicker_contact_dong_trachnhiem_3').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_3').selectpicker('val', this.model.contact_dong_trachnhiem_3);

      $('.selectpicker_contact_dong_trachnhiem_4').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_4').selectpicker('val', this.model.contact_dong_trachnhiem_4);

      $('.selectpicker_contact_dong_trachnhiem_5').selectpicker('refresh');
      $('.selectpicker_contact_dong_trachnhiem_5').selectpicker('val', this.model.contact_dong_trachnhiem_5);
    }, 200);
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();
    }, 400);

    if (key === 'customer_s' && this.model.customer_s ) {
      this.getListCustomers($($event.target).val());
    }

    if (key === 'customer_id' && this.model.customer_id) {
      this.getCustomerContacts($($event.target).val());
    }

    setTimeout(() => {
      if (key === 'loai_purpose' ) {
        $('.selectpicker_muc_dich_vay').selectpicker('refresh');
        $('.selectpicker_muc_dich_vay').selectpicker('val', this.model.loan_purpose);
      }
    }, 450);

    setTimeout(() => {
      if (key === 'loai_taisan_dambao' && ['1','2','3','4'].includes(this.model.loai_taisan_dambao) ) {
        $('.selectpicker_loaihinh_thechap').selectpicker('refresh');
        $('.selectpicker_loaihinh_thechap').selectpicker('val', this.model.loaihinh_thechap);
      }
    }, 450);

  }

  getListCustomers(searchTerm): void {
    this.customersService.getList({status: -1, s: searchTerm, page: 0})
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.customers = resp.content.items;
        }
        setTimeout(() => {
          $('.selectpicker_customer_id').selectpicker('refresh');
        }, 50);

      });
  }

  getCustomerContacts(customerId): void {
    this.customersService.getContacts(customerId)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.customerContacts = resp.content.items;
        }
        setTimeout(() => {
          $('.selectpicker_contact_dong_trachnhiem_1').selectpicker('refresh');
          $('.selectpicker_contact_dong_trachnhiem_2').selectpicker('refresh');
          $('.selectpicker_contact_dong_trachnhiem_3').selectpicker('refresh');
          $('.selectpicker_contact_dong_trachnhiem_4').selectpicker('refresh');
          $('.selectpicker_contact_dong_trachnhiem_5').selectpicker('refresh');
        }, 50);

      });
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loopItem) => {
            return loopItem.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  initMaterialWizard() {
    $('.card-wizard').bootstrapWizard({
      tabClass: 'nav nav-pills',
      nextSelector: '.btn-next',
      previousSelector: '.btn-previous',

      onPrevious(tab, navigation, index) {
      },

      onNext(tab, navigation, index) {
      },

      onInit(tab, navigation, index) {

      },

      onTabClick(tab, navigation, index) {
      },

      onTabShow(tab, navigation, index) {

      }
    });

    setTimeout(() => {
      $('.card.card-wizard').addClass('active');
    }, 600);

    function refreshAnimation($wizard, index) {
      const total = $wizard.find('.nav li').length;
      let liWidth = 100 / total;

      const totalSteps = $wizard.find('.nav li').length;
      let moveDistance = $wizard.width() / totalSteps;
      let indexTemp = index;
      let verticalLevel = 0;

      const mobileDevice = $(document).width() < 600 && total > 3;

      if (mobileDevice) {
        moveDistance = $wizard.width() / 2;
        indexTemp = index % 2;
        liWidth = 50;
      }

      $wizard.find('.nav li').css('width', liWidth + '%');

      const stepWidth = moveDistance;
      moveDistance = moveDistance * indexTemp;

      const $current = index + 1;

      if ($current === 1 || (mobileDevice === true && (index % 2 === 0))) {
        moveDistance -= 8;
      } else if ($current === totalSteps || (mobileDevice === true && (index % 2 === 1))) {
        moveDistance += 8;
      }

      if (mobileDevice) {
        verticalLevel = index / 2;
        verticalLevel = verticalLevel * 38;
      }

      $wizard.find('.moving-tab').css('width', stepWidth);
      $('.moving-tab').css({
        transform: 'translate3d(' + moveDistance + 'px, ' + verticalLevel + 'px, 0)',
        transition: 'all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)'
      });
    }
  }

}
