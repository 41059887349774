import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomersService} from '../../customers.service';
import {CommonService} from '../../../common.service';

declare let $: any;

@Component({
  selector: 'app-customers-comments',
  templateUrl: 'customers-comments.component.html',
  styleUrls: ['customers-comments.component.css']
})
export class CustomersCommentsComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal,
              private commonService: CommonService,
              private customerService: CustomersService) {
  }

  model = {};

  @Input()
  customer: any;

  @Input()
  items: any[] = [];

  ngOnInit() {

  }

  onSubmit(): void {
    this.customerService.addComment(this.customer.id, this.model).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.customer = resp.content.customer;
        this.items = resp.content.items;
        this.model = {};

        this.commonService.showMessage({
          message: 'Cập nhật dữ liệu thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }

    })
  }

  onRemove(comment_id): void {
    this.customerService.removeComment(this.customer.id, comment_id).subscribe(resp => {
      if (!resp) {
        return;
      }

      if (resp.status){
        this.customer = resp.content.customer;
        this.items = resp.content.items;

        this.commonService.showMessage({
          message: 'Đã xoá thành công',
          type: 'success'
        });
      }else{
        this.commonService.showMessage({
          message: resp.message.split("\n").join("<br />"),
          type: 'danger'
        });
      }


    })
  }

}
