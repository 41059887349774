import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AssetsIndexService} from '../../assets-index.service';

@Component({
  selector: 'app-assets-view-movable',
  templateUrl: 'assets-view-movable.html',
  styleUrls: ['assets-view-movable.css']
})
export class AssetsViewMovableComponent implements OnInit {

  constructor(private assetService: AssetsIndexService,
              private modalService: NgbModal,
              public activeModal: NgbActiveModal) { }
  model = {
    asset_type: ''
  };

  @Input()
  item: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
    }
  }

}
