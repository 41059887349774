import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {APP_CONFIG} from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  getList(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('status', filterParams.status).set('s', filterParams.s);
    const url = `${APP_CONFIG.apiUrl}customers/list/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListPotential(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('s', filterParams.s);
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/list_potential/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListHasLoans(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('s', filterParams.s);
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/list_has_loans/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListBadCredit(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('s', filterParams.s);
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/list_bad_credit/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getListMembers(filterParams): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const params = new HttpParams().set('source', filterParams.source).set('s', filterParams.s);
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/list_members/p/${filterParams.page}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url, {params});
  }

  getItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/detail/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  createItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/create`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItem(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/update/id/${model.id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItemPotential(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/update_potential/id/${model.id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateItemHasLoans(model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/update_has_loans/id/${model.id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  updateFeatured(id: any, featured: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/update_featured/id/${id}/featured/${featured}`
      + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  deleteItem(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/delete/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getJobs(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/jobs/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addJob(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/add_job/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeJob(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/remove_job/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getContacts(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/contacts/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addContact(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/add_contact/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeContact(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/remove_contact/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getAssets(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/assets/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addAsset(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/add_asset/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeAsset(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/remove_asset/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  uploadThumbnail(id: number, file: File): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/upload_asset_photo/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(url, formData);
  }

  getIncomes(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/incomes/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addIncome(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/add_income/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeIncome(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/remove_income/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getDebts(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/debts/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addDebt(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/add_debt/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeDebt(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/remove_debt/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getLoans(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/loans/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getUsers(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/users/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addUser(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/add_user/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeUser(id: any, userID: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/remove_user/id/${id}/user_id/${userID}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getComments(id: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/comments/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  addComment(id: any, model: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const url = `${APP_CONFIG.apiUrl}customers/add_comment/id/${id}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.post(url, model);
  }

  removeComment(id: any, commentID: any): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    // tslint:disable-next-line:max-line-length
    const url = `${APP_CONFIG.apiUrl}customers/remove_comment/id/${id}/comment_id/${commentID}`
                + `?api_token=${APP_CONFIG.apiToken}&access_token=${accessToken}`;
    return this.http.get(url);
  }
}
