import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {CommonService} from '../../common.service';
import * as moment from 'moment';
import {ReportsIndexService} from '../reports-index.service';
import {ReportCapitalAddComponent} from '../popups/report-capital-add/report-capital-add.component';
import {ReportCapitalViewComponent} from '../popups/report-capital-view/report-capital-view.component';

declare let $: any;
declare let md: any;
@Component({
  selector: 'app-reports-capital',
  templateUrl: 'reports-capital.component.html',
  styleUrls: ['reports-capital.component.css']
})
export class ReportsCapitalComponent implements OnInit {

  constructor(private reportService: ReportsIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
    filter_from_date: '',
    filter_to_date: '',
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  loading = true;
  items = [];

  ngOnInit() {
    this.onChangeTimePeriod();
    this.loadList();

    setTimeout(() => {
      $('.filter_time_period_selectpicker').selectpicker();
      $('.filter_select_quarter').selectpicker('refresh');
    }, 50);
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = $($event.target).val();
    }, 50);

    this.onChangeTimePeriod();
  }

  onChangeTimePeriod(): void {
    setTimeout(() => {
      $('.filter_select_quarter').selectpicker('refresh');
    }, 50);

    $('.datepicker').datetimepicker({
      useCurrent: false,
      format: 'DD/MM/YYYY',
      icons: {
        time: 'fa fa-clock-o',
        date: 'fa fa-calendar',
        up: 'fa fa-chevron-up',
        down: 'fa fa-chevron-down',
        previous: 'fa fa-chevron-left',
        next: 'fa fa-chevron-right',
        today: 'fa fa-screenshot',
        clear: 'fa fa-trash',
        close: 'fa fa-remove'
      }
    });
  }

  loadList(): void {
    this.loading = false;
    setTimeout(() => {
      this.reportService.getReportCapital(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
      });
    }, 50);
  }

  onFilterSubmit(): void {
    this.loading = true;
    this.loadList();
  }

  modalAdd(lastItem): void {
    const modalRef = this.modalService.open(ReportCapitalAddComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.modal_action = 'add';
    modalRef.componentInstance.items = this.items;
    modalRef.componentInstance.item = lastItem;
    modalRef.componentInstance.modal_title = 'Thêm báo cáo nguồn vốn';
  }

  modalUpdate(item): void {
    const modalRef = this.modalService.open(ReportCapitalAddComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.item = item;
    modalRef.componentInstance.items = this.items;
    modalRef.componentInstance.modal_title = item.lbl_date;
  }

  modalView(itemId): void {
    this.reportService.getCapital(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(ReportCapitalViewComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.item = resp.content;
    });
  }

  onDownload(id): void {
    this.reportService.downReportCapital(id);
  }
}
