import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CommonService} from '../../common.service';
import * as _ from 'lodash';

import {UsersIndexService} from '../users-index.service';
import {GroupsIndexService} from '../../groups/groups-index.service';
import {GroupsViewComponent} from '../../groups/groups-view/groups-view.component';

declare let $: any;

@Component({
  selector: 'app-users-permission-popup',
  templateUrl: 'users-permission-popup.component.html',
  styleUrls: ['users-permission-popup.component.css'],
  providers: [UsersIndexService, CommonService]
})
export class UsersPermissionPopupComponent implements OnInit {

  constructor(private apiService: UsersIndexService,
              private groupService: GroupsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  model = {
    thumbnail_url: '',
    thumbnail_full_url: '',
    password: '',
    id: ''
  };

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  filterParams = {
    s: '',
    page: 1,
  };

  groupUsers: any[] = [];
  groupUserId: any = '';
  groupModel: any;

  permissionSettings: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
      this.groupUserId = this.item.group_id;
      this.model.password = '';
    }

    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));
    this.loadingGroupUser();

    setTimeout(() => {
      $('.selectpicker_group_id').selectpicker('refresh');
      $('.selectpicker_group_id').selectpicker('val', this.groupUserId);
    }, 50);
  }

  loadingGroupUser(): void {
    this.groupService.getList(this.filterParams).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.groupUsers = resp.content.items;

      setTimeout(() => {
        $('.selectpicker_group_id').selectpicker('refresh');
        $('.selectpicker_group_id').selectpicker('val', this.groupUserId);
      }, 50);
    });
  }

  onChangeModelInput(key, $event): void {
    setTimeout(() => {
      this.model[key] = $($event.target).val();

      if (key == 'group_id') {
        const groupId = $($event.target).val();
        this.groupService.getItem(groupId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }
          this.groupModel = resp.content;
          if (!_.isEmpty(this.groupModel.permissionSettings)) {
            this.permissionSettings = this.groupModel.permission_settings;
          };
        });
      }
    }, 400);
  }

  onSubmit(): void {

    if (!this.item) {
      this.onNew();

      return;
    }

    this.onUpdate();
  }

  onNew(): void {
    this.apiService.createItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.items.unshift(resp.content);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUpdate(): void {
    this.apiService.updateItem(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.item = resp.content;
          this.activeModal.close();

          _.remove(this.items, (loopItem) => {
            return loopItem.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }


      });
  }

  onUploadThumbnail(files: FileList): void {
    setTimeout(() => {
      this.apiService.updateThumbnail(this.model.id, files.item(0)).subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status) {
          this.model.thumbnail_url = resp.content.thumbnail_url;
          this.model.thumbnail_full_url = resp.content.thumbnail_full_url;

          this.commonService.showMessage({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message.split('\n').join('<br />'),
            type: 'danger'
          });
        }

      });
    }, 50);
  }

  onUpdatePermissions(permissionSetting: any): void {

  }
}
