import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loans-debts',
  templateUrl: './loans-debts.component.html',
  styleUrls: ['./loans-debts.component.css']
})
export class LoansDebtsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
