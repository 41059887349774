import { Component, OnInit } from '@angular/core';
import {CustomersService} from '../../customers.service';
import {CommonService} from '../../../common.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-customers-view',
  templateUrl: './customers-view.component.html',
  styleUrls: ['./customers-view.component.css']
})
export class CustomersViewComponent implements OnInit {

  constructor(private apiService: CustomersService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
