import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AssetsIndexService} from '../../assets-index.service';
import {CustomersService} from '../../../customers/customers.service';
import * as _ from 'lodash';
declare let $: any;
declare let md: any;

@Component({
  selector: 'app-assets-view',
  templateUrl: 'assets-view.component.html',
  styleUrls: ['assets-view.component.css']
})
export class AssetsViewComponent implements OnInit {

  constructor(private assetService: AssetsIndexService,
              private modalService: NgbModal,
              private customerService: CustomersService,
              public activeModal: NgbActiveModal) { }
  model = {
    asset_type: '',
    contact_ids: {},
  };

  @Input()
  item: any;
  contactNumber: {id, title}[] = [];

  ngOnInit() {
    if (this.item) {
      this.model = this.item;
      this.model = {
        ...this.model,
        contact_ids: JSON.parse(this.item.contact_ids),
      };
    }

    this.getCustomerContacts(this.item.customer_id);
  }

  getCustomerContacts(customerId): void {
    this.customerService.getContacts(customerId)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          /* Input Loading */
          if (!_.isEmpty(resp.content.customer.contacts)) {
            this.contactNumber = resp.content.customer.contacts;

            const contactIdArr = Object.entries(this.model.contact_ids).map(e => e[1]);
            this.contactNumber = this.contactNumber.filter(item => contactIdArr.includes(item.id));
          }
        }
      });
  }

}
