import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {CommonService} from '../../common.service';
import {ReportsIndexService} from '../reports-index.service';

@Component({
  selector: 'app-reports-client-evaluation',
  templateUrl: 'reports-client-evaluation.component.html',
  styleUrls: ['reports-client-evaluation.component.css']
})
export class ReportsClientEvaluationComponent implements OnInit {

  constructor(private reportService: ReportsIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: {
    'id': '',
    'customer_id': '',
    'customer_name': '',
    'tong_ho_so': '',
    'tong_amt': '',
    'tong_so_ho_so_tra_dung_han': '0',
    'tong_so_ho_so_tra_tre_han': '0',
    'tong_so_ho_so_nguy_co_mat_von': '0',
    'tong_paid_amt': '0.00',
    'tong_paid_interest_amt': '0.00',
    'so_tien_lai_du_kien_nhan_duoc': '0.00',
    'so_tien_goc_con_phai_thanh_toan': '0'
  }[] = [];
  loading = true;

  ngOnInit() {
    this.loadList();

  }

  loadList(): void {
    setTimeout(() => {
      this.reportService.getReportClient(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
      });
    }, 50);
  }
}
