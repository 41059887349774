import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomersService} from '../../../customers/customers.service';
import {CommonService} from '../../../common.service';
import {ReportsIndexService} from '../../reports-index.service';
import * as moment from 'moment';

declare let $: any;

@Component({
  selector: 'app-report-income-add',
  templateUrl: './report-income-add.component.html',
  styleUrls: ['./report-income-add.component.css']
})
export class ReportIncomeAddComponent implements OnInit {

  constructor(private customersService: CustomersService,
              private reportService: ReportsIndexService,
              private commonService: CommonService,
              public activeModal: NgbActiveModal) { }

  @Input()
  items: any[] = [];

  @Input()
  item: any;

  model = {
    date: moment(new Date()).format('DD/MM/YYYY'),
    lbl_date: moment(new Date()).format('DD/MM/YYYY'),
    lai_cho_vay: '',
    lai_tien_gui: '',
    lai_khac: '',
    phi_lai_tien_gui: '',
    phi_lai_tctd_khac: '',
    phi_hoat_dong: '',
    phi_phat_sinh: '',
    loi_nhuan: ''
  };

  @Input()
  modal_action: any;

  ngOnInit() {
    if (this.item) {
      this.model = this.item;

      this.model.date = this.item.lbl_date;
    }
  }

  onSubmit(): void {
    if (this.modal_action === 'add') {
      this.onNew();
      return;
    }
    this.onUpdate();
  }

  onNew(): void {
    this.reportService.createIncome(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.model.loi_nhuan = this.calcLoiNhuan();
          this.items.unshift(this.model);
          this.activeModal.close();

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  onUpdate(): void {
    this.reportService.createIncome(this.model)
      .subscribe(resp => {
        if (!resp) {
          return;
        }

        if (resp.status === 1) {
          this.model.loi_nhuan = this.calcLoiNhuan();
          this.item = this.model;
          this.activeModal.close();

          _.remove(this.items, (loopItem) => {
            return loopItem.id === this.item.id;
          });

          this.items.unshift(this.item);

          this.commonService.showMessage({
            message: 'Cập nhật dữ liệu thành công',
            type: 'success'
          });
        } else {
          this.commonService.showMessage({
            message: resp.message,
            type: 'danger'
          });
        }
      });
  }

  calcLoiNhuan() {
    const {lai_cho_vay,
      lai_tien_gui,
      lai_khac,
      phi_lai_tien_gui,
      phi_lai_tctd_khac,
      phi_hoat_dong,
      phi_phat_sinh} = this.model;

    const loiNhuan = (+lai_cho_vay + +lai_tien_gui + +lai_khac) - (+phi_lai_tien_gui + +phi_lai_tctd_khac + +phi_hoat_dong + +phi_phat_sinh);
    return loiNhuan.toString();
  }
}
