import { Component, OnInit } from '@angular/core';
import {UsersIndexService} from '../users-index.service';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {PopupComponent} from '../../_shared/popup/popup.component';
import {CommonService} from '../../common.service';

import {UsersPermissionPopupComponent} from '../users-permission-popup/users-permission-popup.component';
import {UsersViewComponent} from '../users-view/users-view.component';


@Component({
  selector: 'app-users-permissions',
  templateUrl: './users-permissions.component.html',
  styleUrls: ['./users-permissions.component.css']
})
export class UsersPermissionsComponent implements OnInit {

  constructor(private userService: UsersIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: any[] = [];
  loading = true;

permissionSettings: any;

  ngOnInit() {
    this.userService.validateAccessToken();
    this.loadList();
    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));
  }

  filterList(): void {
    this.filterParams.page = 1;
    this.loadList();
  }



  loadList(): void {
    setTimeout(() => {
      this.userService.getList(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.items = resp.content.items;
        this.total = resp.content.total;
        this.ppp = resp.content.ppp;
      });
    }, 50);
  }

  modalView(itemId): void {
    this.userService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(UsersViewComponent, {
        /*size: 'lg'*/
      });
      modalRef.componentInstance.item = resp.content;
    });
  }

  modalUpdate(itemId) {
    this.userService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(UsersPermissionPopupComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.modal_title = `<strong>${resp.content.display_name}</strong>`;
      modalRef.componentInstance.items = this.items;
      modalRef.componentInstance.item = resp.content;
    });

  }

  modalDelete(itemId) {
    this.userService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Xóa dữ liệu';
      modalRef.componentInstance.description = `Bạn có muốn xóa thành viên <strong>${resp.content.display_name}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.userService.deleteItem(itemId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          _.remove(this.items, (loop_item) => {
            return loop_item.id === itemId;
          });

          this.commonService.showMessage({
            message: 'Dữ liệu đã được xóa khỏi hệ thống',
            type: 'success',
          });
        });
      });
    });

  }

}
