import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  @Input()
  title = 'Xác nhận';

  @Input()
  description = 'Bạn có muốn thực hiện thao tác của bạn không?';

  @Input()
  confirm_text = 'Xác nhận';

  @Input()
  cancel_text = 'Hủy bỏ';

  @Input()
  formControl = {
    active: false,
    label: 'Test',
    value: ''
  };

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  onConfirm(): void {
    if (this.formControl.active) {
      this.activeModal.close(this.formControl.value);
      return;
    }
    this.activeModal.close('Confirmed');
  }

}
