import { Component, OnInit } from '@angular/core';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {CommonService} from '../../common.service';



@Component({
  selector: 'app-reports-asset-evaluation',
  templateUrl: 'reports-asset-evaluation.component.html',
  styleUrls: ['reports-asset-evaluation.component.css']
})
export class ReportsAssetEvaluationComponent implements OnInit {

  constructor(
              private commonService: CommonService,
              private modalService: NgbModal) { }

  filterParams = {
    s: '',
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;
  items: any[] = [];

  ngOnInit() {
  }

  filterList(): void {
  }

}
