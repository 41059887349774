import {Component, OnInit} from '@angular/core';
import {UsersIndexService} from '../../users/users-index.service';

import {APP_CONFIG} from '../../app.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

import {PopupComponent} from '../../_shared/popup/popup.component';
import {CommonService} from '../../common.service';
import {RemindersService} from '../reminders.service';
import {CustomersService} from '../../customers/customers.service';
import {RemindersViewComponent} from '../popups/reminders-view/reminders-view.component';
import {RemindersAddComponent} from '../popups/reminders-add/reminders-add.component';
import {RemindersCommentsComponent} from '../popups/reminders-comments/reminders-comments.component';
import {CustomersViewComponent} from '../../customers/popups/customers-view/customers-view.component';
import {LoansService} from '../loans.service';
import {LoansViewComponent} from '../popups/loans-view/loans-view.component';

declare let $: any;

@Component({
  selector: 'app-loan-reminders',
  templateUrl: './loan-reminders.component.html',
  styleUrls: ['./loan-reminders.component.css']
})
export class LoanRemindersComponent implements OnInit {

  constructor(private reminderService: RemindersService,
              private customerService: CustomersService,
              private loanService: LoansService,
              private userService: UsersIndexService,
              private commonService: CommonService,
              private modalService: NgbModal) {
  }

  filterParams = {
    template: -1,
    status: -1,
    s: '',
    customer_id: 0,
    page: 1,
  };

  ppp: number = APP_CONFIG.ppp;
  total = 0;

  count = 0;
  count_total = 0;
  count_1 = 0;
  count_1_total = 0;
  count_2 = 0;
  count_2_total = 0;
  count_3 = 0;
  count_3_total = 0;
  count_4 = 0;
  count_4_total = 0;
  count_5 = 0;
  count_5_total = 0;

  items: any[] = [];

  customers: any[] = [];
  downloadItem: any;
  clickedOutsideCount = 0;
  loading = true;

  permissionSettings: any;

  ngOnInit() {
    this.userService.validateAccessToken();

    this.loadList();
    this.permissionSettings = JSON.parse(localStorage.getItem('signedUser_permission_settings'));

    this.customerService.getList({
      status: 0,
      s: '',
      page: 0,
    }).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      this.customers = resp.content.items;

      $(document).ready(function() {
        $('.filter_selectpicker').selectpicker();
        $('.filter_condition_selectpicker').selectpicker();
        $('.filter_status_selectpicker').selectpicker();
        setTimeout(() => {
          $('.selectpicker_noti_type').selectpicker('refresh');
          $('.selectpicker_noti_type').selectpicker('val', this.filterParams.template);
        }, 50);
      });

    });
  }

  filterList(): void {
    this.filterParams.page = 1;
    this.loadList();
  }

  onChangeFilterParams(key, $event): void {
    setTimeout(() => {
      this.filterParams[key] = $($event.target).val();
      this.loadList();
    }, 400);
  }


  loadList(): void {
    setTimeout(() => {
      this.reminderService.getList(this.filterParams).subscribe(resp => {
        if (!resp || !resp.status) {
          return;
        }
        this.loading = false;
        this.count = resp.content.count;
        this.count_total = resp.content.count_total;
        this.count_1 = resp.content.count_1;
        this.count_1_total = resp.content.count_1_total;
        this.count_2 = resp.content.count_2;
        this.count_2_total = resp.content.count_2_total;
        this.count_3 = resp.content.count_3;
        this.count_3_total = resp.content.count_3_total;
        this.count_4 = resp.content.count_4;
        this.count_4_total = resp.content.count_4_total;
        this.count_5 = resp.content.count_5;
        this.count_5_total = resp.content.count_5_total;

        this.items = resp.content.items;
        this.total = resp.content.total;
        this.ppp = resp.content.ppp;
      });
    }, 50);
  }

  modalComments(itemId): void {
    this.reminderService.getComments(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(RemindersCommentsComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.reminder = resp.content.reminder;
      modalRef.componentInstance.items = resp.content.items;
    });
  }

  modalView(itemId): void {
    this.reminderService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(RemindersViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
    });
  }

  modalViewLoan(itemId): void {
    this.loanService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(LoansViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
    });
  }

  modalViewCustomer(itemId): void {
    this.customerService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }

      const modalRef = this.modalService.open(CustomersViewComponent, {
        size: 'lg'
      });
      modalRef.componentInstance.item = resp.content.item;
    });
  }

  incrementClickOutsideCount(itemID) {
    this.clickedOutsideCount += 1;
    if (this.clickedOutsideCount > 1) {
      this.showDownload(itemID);
    }
  }

  showDownload(itemID): void {
    this.clickedOutsideCount = 0;

    if (this.downloadItem === itemID) {
      this.downloadItem = '';
    } else {
      this.downloadItem = itemID;
    }
  }

  modalDownload(itemId, template): void {
    this.loanService.downloadHopDong(itemId, template);
  }

  modalAdd() {
    const modalRef = this.modalService.open(RemindersAddComponent, {
      size: 'lg'
    });
    modalRef.componentInstance.modal_action = 'add';
    modalRef.componentInstance.modal_title = 'Tạo hồ sơ vay mới';
    modalRef.componentInstance.items = this.items;
  }

  modalUpdate(itemId) {
    this.reminderService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(RemindersAddComponent, {
        size: 'lg'
      });

      modalRef.componentInstance.modal_action = 'update';
      modalRef.componentInstance.modal_title = `<strong>${resp.content.item.title}</strong>`;
      modalRef.componentInstance.items = this.items;
      modalRef.componentInstance.item = resp.content.item;
    });

  }

  modalSendSMS(itemId) {
    this.reminderService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Gửi SMS đến khách hàng';
      modalRef.componentInstance.description = `Bạn có muốn gửi SMS đến số điện thoại <strong>${resp.content.customer_mobile}</strong> của khách hàng <strong>${resp.content.customer_title}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.reminderService.sendSMS(itemId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          if (resp.status == 1) {
            _.remove(this.items, (loop_item) => {
              return loop_item.id === resp.content.id;
            });

            this.items.unshift(resp.content);

            this.commonService.showMessage({
              message: 'Gửi SMS thành công',
              type: 'success'
            });
          } else {
            this.commonService.showMessage({
              message: resp.message,
              type: 'danger'
            });
          }
        });
      });
    });

  }

  modalSendEmail(itemId) {
    this.reminderService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Gửi email đến khách hàng';
      modalRef.componentInstance.description = `Bạn có muốn gửi email đến <strong>${resp.content.customer_email}</strong> của khách hàng <strong>${resp.content.customer_title}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.reminderService.sendEmail(itemId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          if (resp.status == 1) {
            _.remove(this.items, (loop_item) => {
              return loop_item.id === resp.content.id;
            });

            this.items.unshift(resp.content);

            this.commonService.showMessage({
              message: 'Gửi email thành công',
              type: 'success'
            });
          } else {
            this.commonService.showMessage({
              message: resp.message,
              type: 'danger'
            });
          }
        });
      });
    });

  }

  onDownload(id): void {
    this.reminderService.download(id);
  }

  modalConfirm(itemId) {
    this.reminderService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Xác nhận đã gửi thông báo';
      modalRef.componentInstance.description = `Bạn có muốn xác nhận trạng thái đã gửi cho thông báo số <strong>${resp.content.item.lbl_id}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.reminderService.updateStatus(itemId, 1).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          if (resp.status == 1) {
            _.remove(this.items, (loop_item) => {
              return loop_item.id === resp.content.id;
            });

            this.items.unshift(resp.content);

            this.commonService.showMessage({
              message: 'Cập nhật dữ liệu thành công',
              type: 'success'
            });
          } else {
            this.commonService.showMessage({
              message: resp.message,
              type: 'danger'
            });
          }
        });
      });
    });

  }

  modalDelete(itemId) {
    this.reminderService.getItem(itemId).subscribe(resp => {
      if (!resp || !resp.status) {
        return;
      }
      const modalRef = this.modalService.open(PopupComponent, {
        size: 'sm'
      });
      modalRef.componentInstance.title = 'Xóa dữ liệu';
      modalRef.componentInstance.description = `Bạn có muốn xóa hồ sơ vay <strong>${resp.content.item.title}</strong> không?`;

      modalRef.result.then(result => {
        if (result !== 'Confirmed') {
          return;
        }

        this.reminderService.deleteItem(itemId).subscribe(resp => {
          if (!resp || !resp.status) {
            return;
          }

          _.remove(this.items, (loop_item) => {
            return loop_item.id === itemId;
          });

          this.commonService.showMessage({
            message: 'Dữ liệu đã được xóa khỏi hệ thống',
            type: 'success',
          });
        });
      });
    });

  }

}
